import { createContext, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { AdminService, GetJobUtterancesResponseDto, MediaService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  jobId: string;
  isLoading: boolean;
  utterances: GetJobUtterancesResponseDto['utterances'];
  transcriptFile: string;
  setTranscriptFile: (filename: string) => void;
  audioUrl: string | null;
};

const PageContext = createContext<TState>({
  jobId: '',
  isLoading: false,
  utterances: [],
  transcriptFile: 'asr.json',
  setTranscriptFile: () => {},
  audioUrl: null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const params = useParams() as { id: string };
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [transcriptFile, setTranscriptFile] = useState<string>(searchParams.get('transcript') ?? 'asr.json');
  const jobId = params?.id ?? '';

  const [utterances, setUtterances] = useState<GetJobUtterancesResponseDto['utterances']>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    load(transcriptFile);
  }, [jobId, transcriptFile]);

  const load = async (transcript: string) => {
    setIsLoading(true);
    call(AdminService.getJobUtterances({ id: jobId, transcript })).then((res: GetJobUtterancesResponseDto) => {
      setUtterances(res.utterances || []);
      call(MediaService.getSignedUrl({ id: jobId, file: 'output.mp3' })).then((res: { signedUrl: string }) => {
        setAudioUrl(res.signedUrl);
        setIsLoading(false);
      });
    });
  };

  return (
    <PageContext.Provider
      value={{
        jobId,
        isLoading,
        utterances,
        transcriptFile,
        setTranscriptFile,
        audioUrl,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
