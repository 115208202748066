import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';

import { Modal } from './Modal';

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #858dbd;
  margin: 0 0 10px;
  text-align: center;
`;

const Wrapper = styled.div`
  background: #ffffff;
  width: 660px;
  padding: 30px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

type TProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ASOneVideo = ({ isOpen, onClose }: TProps) => {
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Wrapper>
        <Title style={{ margin: '0 0 25px', textAlign: 'center' }}>AutoScript One</Title>
        <iframe
          src="https://www.youtube.com/embed/Gx6qPF3mP9M"
          name="myiFrame"
          scrolling="no"
          frameBorder="1"
          height="400px"
          width="600px"
          allowFullScreen
        ></iframe>
        <div style={{ marginTop: '20px' }}>
          <Button onClick={onClose}>Got it!</Button>
        </div>
      </Wrapper>
    </Modal>
  );
};
