import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { useModalContext } from '@providers/ModalProvider';
import { createASOnePlan } from '@queries/admin/asone/createASOnePlan';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Switch } from '@components/form-elements/Switch';
import { EErrorMessages } from '@constants/errorMessages';
import { H30pxInput } from '@components/form-elements/Input/variations/H30pxInput';
import { H30pxCurrencyInput } from '@components/form-elements/CurrencyInput/variations/H30pxCurrencyInput';
import { Select } from '@components/form-elements/Select';

import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const NewASOnePlanModal = ({ onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [plan, setPlan] = useState<TSubscriptionProduct>({
    id: '',
    Subscriptions: [],
    capacityInSeconds: 0,
    index: 0,
    stripeItemPriceId: '',
    stripeProductId: '',
    subscriptionProductCategory: 'AutoScriptOne',
    type: '',
    name: '',
    description: '',
    price: 125,
    public: true,
    trialPeriodDays: 0,
    freeSeats: 0,
    rsPro: false,

    asrRate: 0,
    postRate: 0,
    reporterUnlockRate: 0,
    fullUnlockRate: 0,

    asrRateType: 'FLAT_FEE',
    postRateType: 'FLAT_FEE',
    reporterUnlockRateType: 'FLAT_FEE',
    fullUnlockRateType: 'FLAT_FEE',

    defaultBillingConfigurationId: null,
    audioHoursPerMonth: 20,
    inPersonCaptureChannels: 4,
    allowedToChooseTranscriptUnlockType: false,
  });

  const tierTypes = [
    { label: 'Flat Fee', value: 'FLAT_FEE' },
    { label: 'Fixed per hour rate', value: 'PER_HOUR' },
    { label: 'Tier Rate', value: 'TIER_RATE' },
  ];

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await createASOnePlan(plan);
      setIsSubmitting(false);
      closeModal();
      onSuccess();
    } catch (error) {
      setIsSubmitting(false);
      toast.error(EErrorMessages.DEFAULT);
    }
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Create new plan</h2>

      <div className="input-wrapper">
        <div className="label">Plan title</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlan({ ...plan, name: e.target.value })}
          label="Plan title"
          value={plan.name}
        />
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Monthly price per license</div>
          <H30pxCurrencyInput
            onChange={(_, v2) => {
              setPlan({ ...plan, price: v2 });
            }}
            label="$ 0"
            value={plan.price}
          />
        </div>

        {/* <div className="switch-wrapper items-center">*/}
        {/*  <div className="label">Reporter Studio Pro</div>*/}
        {/*  <Switch className="switch" checked={plan.rsPro} onChange={() => setPlan({ ...plan, rsPro: !plan.rsPro })} />*/}
        {/* </div>*/}
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Realtime / ASR rate</div>

          <Select
            name="asrRateType"
            label="Type"
            value={plan.asrRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, asrRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.asrRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, asrRate: v2 });
              }}
              label="$ 0"
              value={plan.asrRate}
            />
          ) : null}
        </div>
        <div className="input-wrapper">
          <div className="label">Post rate</div>
          <Select
            name="postRateType"
            label="Type"
            value={plan.postRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, postRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.postRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, postRate: v2 });
              }}
              label="$ 0"
              value={plan.postRate}
            />
          ) : null}
        </div>
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Reporter Unlock rate</div>
          <Select
            name="reporterUnlockRateType"
            label="Type"
            value={plan.reporterUnlockRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, reporterUnlockRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.reporterUnlockRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, reporterUnlockRate: v2 });
              }}
              label="$ 0"
              value={plan.reporterUnlockRate}
            />
          ) : null}
        </div>
        <div className="input-wrapper">
          <div className="label">Full Unlock rate</div>
          <Select
            name="fullUnlockRateType"
            label="Type"
            value={plan.fullUnlockRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, fullUnlockRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.fullUnlockRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, fullUnlockRate: v2 });
              }}
              label="$ 0"
              value={plan.fullUnlockRate}
            />
          ) : null}
        </div>
      </div>

      <div className="input-wrapper">
        <div className="label">Free trial days</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, trialPeriodDays: parseInt(e.target.value, 10) || 0 })
          }
          label="Number of free trial days"
          value={plan.trialPeriodDays}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Free Seats</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, freeSeats: parseInt(e.target.value, 10) || 0 })
          }
          label="Number of free seats in this plan"
          value={plan.freeSeats}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Audio hours per month</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, audioHoursPerMonth: parseInt(e.target.value, 10) || 0 })
          }
          label="Audio hours per month"
          value={plan.audioHoursPerMonth}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">In-person capture channels</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, inPersonCaptureChannels: parseInt(e.target.value, 10) || 0 })
          }
          label="In-person capture channels"
          value={plan.inPersonCaptureChannels}
        />
      </div>

      <div className="form-switch">
        <div className="label">Public plan</div>
        <Switch checked={plan.public} onChange={() => setPlan({ ...plan, public: !plan.public })} />
      </div>

      <div className="form-switch">
        <div className="label">Allowed to choose Transcript Unlock Type</div>
        <Switch
          checked={plan.allowedToChooseTranscriptUnlockType}
          onChange={() =>
            setPlan({ ...plan, allowedToChooseTranscriptUnlockType: !plan.allowedToChooseTranscriptUnlockType })
          }
        />
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </Wrapper>
  );
};
