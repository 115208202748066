import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { AdminService, EnhancementsDto, MetadataDto } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { TJob } from 'app/types/entities/TJob';
import { jobCanBeDownloaded } from '@helpers/jobStatus/jobCanBeDownloaded';
import { useWebsocketContext } from '@providers/WebsocketProvider';

const { getJobById } = AdminService;

type TState = {
  jobId: string;
  isLoading: boolean;
  job?: TJob;
  showMaterials: boolean;
  showSchedule: boolean;
  showDownloads: boolean;
  manualReload: any;
  enhancements: EnhancementsDto | null;
  metadata: MetadataDto | null;
};

const PageContext = createContext<TState>({
  jobId: '',
  isLoading: false,
  job: undefined,
  showMaterials: false,
  showSchedule: false,
  showDownloads: false,
  manualReload: () => {},
  enhancements: null,
  metadata: null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { jobId: jobChangedId } = useWebsocketContext();
  const params = useParams() as { id: string };
  const jobId = params?.id ?? '';

  const [job, setJob] = useState<TJob>();
  const [enhancements, setEnhancements] = useState<EnhancementsDto | null>(null);
  const [metadata, setMetadata] = useState<MetadataDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [manualTrigger, setManualTrigger] = useState(false);

  const showDownloads = !!(job?.status && job?.results?.length && jobCanBeDownloaded(job?.status));
  const showMaterials = !!(job?.notes || showDownloads);
  const showSchedule = !!job?.meetingType;

  const manualReload = () => setManualTrigger(!manualTrigger);

  useEffect(() => {
    if (jobChangedId !== jobId) {
      return;
    }
    load();
  }, [jobChangedId]);

  useEffect(() => {
    load();
  }, [jobId, manualTrigger]);

  const load = async () => {
    setIsLoading(true);
    const job: TJob = await call(getJobById({ id: jobId }));
    setJob(job);
    const { enhancements, metadata } = await call(AdminService.getJobEnhancements({ id: jobId }));
    setEnhancements(enhancements);
    setMetadata(metadata);
    setIsLoading(false);
  };

  return (
    <PageContext.Provider
      value={{
        jobId,
        isLoading,
        job,
        showMaterials,
        showSchedule,
        showDownloads,
        manualReload,
        enhancements,
        metadata,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
