import styled from 'styled-components';

import { TProps } from './types/TProps';
import { getButtonSize } from './helpers/getButtonSize';
import { getVariant } from './helpers/getVariant';

export const Button = styled.button<TProps>`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;

  :disabled {
    cursor: auto;
  }

  ${(props) => getButtonSize(props.size)};
  ${(props) => getVariant(props.variant || 'contained')};
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.bgColor && `background: ${props.bgColor}`};
  ${(props) => props.borderColor && `border: 1px solid ${props.borderColor}`};
  ${(props) =>
    props.borderRadius &&
    `
    border-radius: 0;
    border-top-left-radius: ${props.borderRadius.topLeft};
    border-top-right-radius: ${props.borderRadius.topRight};
    border-bottom-left-radius: ${props.borderRadius.bottomLeft};
    border-bottom-right-radius: ${props.borderRadius.bottomRight};
  `};
`;
