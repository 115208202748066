import styled from 'styled-components';

type TWProps = {
  active?: boolean;
};
export const Wrapper = styled.div<TWProps>`
  width: 245px;
  height: 280px;
  padding-bottom: 0;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => props.active && 'background: #CADCF8;'};

  .content {
    padding: 20px;
    padding-bottom: 10px;
  }

  .plan-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #40608f;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price {
    font-size: 58px;
    line-height: 80px;
    font-weight: 600;
    color: #7cb1fe;
    margin-top: -5px;
    ${(props) => props.active && 'color: #fff;'};
  }

  h3 {
    color: #858dbd;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .chosenContainer {
    height: 40px;
    margin: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    color: #fff;
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
  }
  .buttonContainer {
    height: 40px;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #d5ddf2;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.active && 'border-top: 1px solid #40608F;'};

    & button {
      border-radius: 10px;
      margin: 0;
      height: 36px;
      width: 244px;
      ${(props) => props.active && 'color: #40608F;'};

      :disabled {
        background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
        color: white;
      }
    }
  }
`;
