import { ElementRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { client } from 'app/http';
import { fakeDownloadClick } from '@helpers/jobLinkHelpers';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { DownloadJob } from '@components/icons/DownloadJob';

import { Controls } from './components/Controls';
import { ProgressBar } from './components/ProgressBar';
import { Volume } from '@components/AudioPlayer/components/Volume';

export interface AudioPlayerHandle {
  seekTo: (time: number) => void;
}

export const AudioPlayer = forwardRef<AudioPlayerHandle, TProps & { className?: string }>(
  ({ className = 'audio-player', src, onPlay }, ref) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef<ElementRef<'audio'>>(null);
    const progressBarRef = useRef<ElementRef<'input'>>(null);

    const seekTo = (time: number) => {
      if (audioRef.current) {
        audioRef.current.currentTime = time;
        if (progressBarRef.current) {
          progressBarRef.current.value = time.toString();
          progressBarRef.current.style.setProperty('--range-progress', `${(time / duration) * 100}%`);
        }
        setTimeProgress(time);
      }
    };

    useImperativeHandle(ref, () => ({
      seekTo,
    }));

    useEffect(() => {
      if (!audioRef.current) {
        return;
      }
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }, [isPlaying]);

    useEffect(() => {
      const audioEl = audioRef.current;
      if (!audioEl) {
        return;
      }
      const handleTimeUpdate = () => {
        const currentTime = audioEl.currentTime;
        setTimeProgress(currentTime);
        if (progressBarRef.current) {
          progressBarRef.current.value = currentTime.toString();
          progressBarRef.current.style.setProperty('--range-progress', `${(currentTime / duration) * 100}%`);
        }
        if (onPlay) {
          onPlay(currentTime);
        }
      };

      audioEl.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        audioEl.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }, [duration, onPlay]);

    const download = () => {
      client
        .get(src, {
          responseType: 'blob',
        })
        .then(fakeDownloadClick('output.mp3'));
    };

    const onLoadedMetadata = () => {
      if (!audioRef.current) {
        return;
      }
      const seconds = audioRef.current.duration;
      setDuration(seconds);
    };

    if (!src) {
      return null;
    }

    return (
      <Wrapper className={className}>
        <audio
          className="audio"
          src={src}
          ref={audioRef}
          onLoadedMetadata={onLoadedMetadata}
          onSeeking={() => setIsLoading(true)}
          onSeeked={() => setIsLoading(false)}
          onWaiting={() => setIsLoading(true)}
          onEnded={() => {
            setIsPlaying(false);
          }}
        />
        <Controls
          disabled={!progressBarRef.current || !duration}
          isPlaying={isPlaying}
          isLoading={isLoading}
          setIsPlaying={setIsPlaying}
        />
        <ProgressBar progressBarRef={progressBarRef} seekTo={seekTo} timeProgress={timeProgress} duration={duration} />
        <Volume audioRef={audioRef} />
        <DownloadJob className="download-button" onClick={download} />
      </Wrapper>
    );
  },
);

AudioPlayer.displayName = 'AudioPlayer';
