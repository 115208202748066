import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AdminService, CanvasService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { GenericPageError } from '@components/GenericPageError';
import { Select } from '@components/form-elements/Select';
import { TOrganization } from 'app/types/entities/TOrganization';
import { AutoCompleteSelect } from '@components/form-elements/AutocompleteSelect';
import { PhoneInput } from '@components/form-elements/PhoneInput';
import { useModalContext } from '@providers/ModalProvider';
import { CheckBox } from '@components/form-elements/CheckBox';

const InputWrapper = styled.div`
  padding: 20px 0 0;
  &.checkbox-wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    color: #858dbd;
    margin-bottom: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  width: auto;
  padding: 30px 0 0;
`;

const Wrapper = styled.div`
  background: #fff;
  width: 330px;
  padding: 30px;
  position: relative;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  & .page-error {
    margin: 15px 7px 0 7px;
    display: block;
  }

  & h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #858dbd;
    margin: 0 0 10px;
    text-align: center;
  }
`;

type TProps = {
  courseId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddCourseStudentModal = ({ onCancel, onSuccess, courseId }: TProps) => {
  const { call } = useAPI();
  const { closeModal } = useModalContext();
  const [closeAfterSubmit, setCloseAfterSubmit] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [phone, setPhone] = useState('');
  const [organization, setOrganization] = useState<string | undefined>();
  const [team, setTeam] = useState<string | undefined>();
  const [license, setLicense] = useState('RSP60');
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<any>([]);
  const [licenses, setLicenses] = useState<any>([]);
  const [orgTeams, setOrgTeams] = useState<any>([]);

  const isValid = email && firstName && secondName && organization && team && license;

  useEffect(() => {
    call(AdminService.getAllOrganizationsList()).then((res) => {
      setOrganizations(res.map((o: TOrganization) => ({ label: `${o.name} (${o.slug})`, value: o.id })));
    });
    setLicenses([
      { label: 'No license', value: 'NO_LICENSE' },
      { label: 'RS Pro: 30 days', value: 'RSP30' },
      { label: 'RS Pro: 60 days', value: 'RSP60' },
    ]);
  }, []);

  useEffect(() => {
    setTeam(undefined);
    setOrgTeams([]);
    if (!organization) {
      return;
    }
    call(AdminService.getTeams({ oid: organization })).then((res) => {
      setOrgTeams(res.map((o: TOrganization) => ({ label: o.name, value: o.id })));
    });
  }, [organization]);

  const submit = async (e: React.FormEvent) => {
    setErrorMessage('');
    if (!isValid) {
      return;
    }
    setLoading(true);
    e.preventDefault();
    const response = await call(
      CanvasService.createCourseStudent({
        courseId,
        requestBody: {
          email,
          name: firstName,
          lastName: secondName,
          phone,
          organization,
          team,
          license,
        },
      }),
    );
    if (response?.success === false && response?.message) {
      setErrorMessage(response?.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    onSuccess();
    if (closeAfterSubmit) {
      closeModal();
    }
  };

  return (
    <Wrapper>
      {loading && <AbsoluteSpinner overlay={true} />}
      <h2>Add new student</h2>
      <GenericPageError message={errorMessage} />
      <form onSubmit={submit}>
        <InputWrapper>
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
            name="firstName"
            label="First name"
            type="text"
            value={firstName}
            required={true}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="lastName"
            label="Last name"
            type="text"
            value={secondName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSecondName(event.target.value)}
            required={true}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value.toLowerCase())}
            required={true}
          />
        </InputWrapper>
        <InputWrapper>
          <PhoneInput
            onChange={(value) => setPhone(value)}
            defaultCountry="US"
            name="phone"
            placeholder="Phone number (e.g. +12223334455)"
            value={phone || ''}
            required={false}
          />
        </InputWrapper>
        <InputWrapper>
          <AutoCompleteSelect
            name="organization"
            label="Organization"
            value={organization}
            onChange={(name, value) => setOrganization(value)}
            options={organizations}
          />
        </InputWrapper>
        <InputWrapper>
          <Select
            name="team"
            label="Team"
            value={team}
            disabled={!organization}
            onChange={(name, value) => setTeam(value)}
            options={orgTeams}
          />
        </InputWrapper>
        <InputWrapper>
          <Select
            name="license"
            label="License"
            value={license}
            onChange={(name, value) => setLicense(value)}
            options={licenses}
          />
        </InputWrapper>
        <InputWrapper className="checkbox-wrapper">
          <CheckBox checked={closeAfterSubmit} onChange={(value) => setCloseAfterSubmit(value)} />
          <label>Close this modal after student is added</label>
        </InputWrapper>

        <ButtonWrapper>
          <Button onClick={onCancel} variant="outlined" size="small">
            Cancel
          </Button>
          <Button size="small" type="submit" disabled={!isValid}>
            Add Student
          </Button>
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};
