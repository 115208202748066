import { useState } from 'react';

import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { secondsToHHMMSS } from '@helpers/secondsToHHMMSS';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';

import { CustomTable } from '@components/Table';
import { Header } from '@components/Header';
import { StatusText } from '@components/Table/StatusText';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Popover } from '@components/Popover';
import { Jobs as JobsIcon } from '@components/icons/Jobs';
import { Refund as RefundIcon } from '@components/icons/Refund';
import { TJobReport } from 'app/types/entities/TJobReport';
import { usePageContext } from '@pages/Admin/JobsReportPage/providers/PageProvider';
import { routes } from '@routes';
import { PagePagination } from '@components/Pagination';

import { StyledIconButton } from './styles/StyledIconButton';
import { RefundIconButton } from './styles/RefundIconButton';
import { Wrapper } from './styles/Wrapper';
import { FlexCellContent } from './styles/FlexCellContent';
import { CellContent } from './styles/CellContent';
import { StyledLink } from './styles/StyledLink';
import { CenteredPercents } from './styles/CenteredPercents';
import { StyledProgressBarWrapper } from './styles/StyledProgressBarWrapper';
import { StyledCircularProgress } from './styles/StyledCircularProgress';
import { StyledProgressBarTextWrapper } from './styles/StyledProgressBarTextWrapper';
import { StyledMoveIconButton } from './styles/StyledMoveIconButton';
import { jobCanBeRefunded } from '@helpers/jobStatus/jobCanBeRefunded';
import { jobCanBeResubmitted } from '@helpers/jobStatus/jobCanBeResubmitted';
import { Filter } from '@pages/User/AllJobs/Filter';
import { EJobPipelineStatuses } from '@constants/enums/jobPipelineStatuses';
import { Move as MoveIcon } from '@components/icons/Move';
import { jobCanBeMoved } from '@helpers/jobStatus/jobCanBeMoved';
import { Done } from '@components/icons/Done';
import styled from 'styled-components';

const AsOneTag = styled.main`
  padding: 4px 6px;
  border-radius: 5px;
  border: 1px solid #d5def2;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  color: #40608f;
  background: #d5def2;
`;

export const Content = () => {
  const {
    jobsReports,
    isLoading,
    search,
    setSearch,
    pageSize,
    setPageSize,
    exportCSV,
    showResubmitJobModal,
    showRefundJobModal,
    showMoveJobToWSModal,
    jobsFilter,
    setJobsFilter,
    statusFilter,
    setStatusFilter,
    asOneFilter,
    setAsOneFilter,
  } = usePageContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const generateJobLink = (jobId: string) => {
    return routes.adminJobDetails.make(jobId);
  };

  const td = [
    {
      headComponent: () => <td>Job Title</td>,
      render: (data: TJobReport) => (
        <div>
          <div className="flex flex-row items-center">
            {data.meetingId ? <AsOneTag className="tag mr-2"> AS ONE </AsOneTag> : null}
            <StyledLink to={generateJobLink(data.id)}>{data.jobName}</StyledLink>
          </div>
          <span style={{ display: 'block' }}>{data.id}</span>
        </div>
      ),
    },
    {
      headComponent: () => <td>Creator</td>,
      render: (data: TJobReport) => (
        <CellContent>
          <span style={{ display: 'block' }}>
            {data.name} {data.lastname}
          </span>
          <span style={{ display: 'block' }}>{data.email}</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Organization</td>,
      render: (data: TJobReport) => (
        <CellContent>
          <StyledLink to={routes.adminOrganizationDetails.make(data.organization?.id)}>
            {data.organization?.name}
          </StyledLink>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Workspace</td>,
      render: (data: TJobReport) => <CellContent>{data.workspace?.name}</CellContent>,
    },
    {
      headComponent: () => <td>Status</td>,
      render: ({ status, asOneLocked }: TJobReport) => (
        <CellContent>
          {asOneLocked && status !== 'JOB_STATUS_ERROR' ? (
            <StatusText status="Locked" />
          ) : (
            <StatusText status={JOB_STATUSES_ENUM[status]} />
          )}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Pipeline Status</td>,
      render: (data: TJobReport) => (
        <CellContent>
          <StatusText status={EJobPipelineStatuses[data.pipelineStatus ?? 'not_started']} />
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Created</td>,
      render: (data: TJobReport) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td>ASR used</td>,
      render: (data: TJobReport) => <CellContent>{secondsToHHMMSS(data.duration)}</CellContent>,
    },
    {
      headComponent: () => <td>Price</td>,
      render: (data: TJobReport) => {
        return data.price ? <CellContent>{formatCentsPrice(data.price ?? 0)}</CellContent> : null;
      },
    },
    {
      headComponent: () => <td>Refunded</td>,
      render: (data: TJobReport) => {
        return data.refunded ? <CellContent>Yes</CellContent> : null;
      },
    },
    {
      headComponent: () => <td>Transactions</td>,
      render: (data: TJobReport) => {
        return data.Transactions?.length ? (
          <CellContent>
            {data.Transactions.map((transaction) => (
              <div key={transaction.id}>
                <small>
                  {transaction.code.name} {transaction.id}
                </small>
              </div>
            ))}
          </CellContent>
        ) : null;
      },
    },
    {
      headComponent: () => <td>ASOne job</td>,
      render: (data: TJobReport) => {
        return <CellContent>{data.meetingId ? <Done width="16px" /> : null}</CellContent>;
      },
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Edits %</td>,
      render: (data: TJobReport) => {
        const percent = Math.round(data.editingProgress || 0);
        return (
          <CenteredPercents>
            {JOB_STATUSES_ENUM[data.status] === JOB_STATUSES_ENUM.JOB_STATUS_TRANSCRIBING ? (
              <>
                <StyledProgressBarWrapper>
                  <StyledCircularProgress
                    size={20}
                    trackWidth={3}
                    indicatorWidth={3}
                    progress={percent}
                    trackColor="#CADCF8"
                    indicatorColor="#858dbd"
                  />
                  <StyledProgressBarTextWrapper>{percent}%</StyledProgressBarTextWrapper>
                </StyledProgressBarWrapper>
              </>
            ) : null}
          </CenteredPercents>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TJobReport) => {
        const showResubmitButton = jobCanBeResubmitted(data.status);
        const showMoveButton = !data.asOneLocked && jobCanBeMoved(data.status);
        const showRefundButton = (jobCanBeRefunded(data.status) || data.partialUnlocked) && !data.refunded;
        return (
          <FlexCellContent>
            {showMoveButton ? (
              <StyledMoveIconButton
                title="Move to another Workspace"
                onClick={() => showMoveJobToWSModal(data.id, data.workspace.id)}
              >
                <MoveIcon />
              </StyledMoveIconButton>
            ) : null}
            {showResubmitButton ? (
              <StyledIconButton title="Resubmit" onClick={() => showResubmitJobModal(data)}>
                <JobsIcon />
              </StyledIconButton>
            ) : null}
            {showRefundButton ? (
              <RefundIconButton title="Refund" onClick={() => showRefundJobModal(data)}>
                <RefundIcon />
              </RefundIconButton>
            ) : null}
          </FlexCellContent>
        );
      },
    },
  ];

  const FilterPopover = () => {
    return (
      <Popover
        styles="margin-top: 40px; margin-left: -100px;"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Filter
          jobsFilter={jobsFilter}
          jobsFilterOnChange={(e) => setJobsFilter(e)}
          statusFilter={statusFilter}
          statusFilterOnChange={(e) => setStatusFilter(e)}
          handleSubmit={() => {}}
          handleClose={() => setAnchorEl(null)}
          asOneFilter={asOneFilter}
          setAsOneFilter={setAsOneFilter}
        />
      </Popover>
    );
  };

  return (
    <>
      <Header
        title="Jobs Report"
        setSearch={setSearch}
        search={search}
        buttonLabel={jobsReports?.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={jobsReports?.data?.length ? () => exportCSV() : undefined}
        onFilterClick={(e) => setAnchorEl(e.currentTarget)}
        filterCounter={jobsFilter.from || jobsFilter.to || statusFilter.length || asOneFilter ? 1 : 0}
      />
      <LinearProgressLoader active={isLoading} />

      <Wrapper>
        <CustomTable data={jobsReports?.data ?? []} head={td} fullPage={true} />
        <PagePagination totalItemsCount={jobsReports?.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
      </Wrapper>

      <FilterPopover />
    </>
  );
};
