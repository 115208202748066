const getRateTypeSuffix = (rateType: string) => {
  if (rateType === 'PER_HOUR') {
    return `/hr (fixed)`;
  }
  if (rateType === 'TIER_RATE') {
    return `/hr`;
  }
  if (rateType === 'FLAT_FEE') {
    return '';
  }
};

export const formatRate = (v: number | string, rateType: string, label: string, addPrefix = true) => {
  const numericPrice = typeof v === 'string' ? Number(v) : v;
  const disallowed = rateType === 'DISALLOWED';
  const prefix = disallowed ? '✗' : '✓';
  const formattedPrice = disallowed
    ? 'N/A'
    : !numericPrice
      ? 'Free'
      : numericPrice >= 100
        ? `$${numericPrice}`
        : `$${numericPrice.toFixed(2)}`;
  const suffix = disallowed || !numericPrice ? '' : getRateTypeSuffix(rateType);
  return `${addPrefix ? `${prefix} ` : ''}${label}: ${formattedPrice}${suffix}`;
};
