import { useNavigate } from 'react-router';

import { useAuthContext } from '@providers/AuthProvider';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { routes } from '@routes';

import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { Switch } from '@components/form-elements/Switch';
import { Desktop } from '@components/icons/Desktop';
import { DoubleDone } from '@components/icons/DoubleDone';
import { Done } from '@components/icons/Done';
import { Button } from '@components/form-elements/buttons/Button';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { TUser } from 'app/types/entities/TUser';

import { usePageContext } from '../../providers/PageProvider';

import { Wrapper } from './styles/Wrapper';
import { CellContent } from './styles/CellContent';
import { SwitchWrapper } from './styles/SwitchWrapper';
import { Avatar } from './styles/Avatar';
import { AvatarName } from './styles/AvatarName';
import { StyledLink } from './styles/StyledLink';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

export const Content = () => {
  const navigate = useNavigate();
  const { me } = useAuthContext();
  const { id, users, isLoading, changeEnabled } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TUser) => {
        const avatarName = `${data?.name[0]}${data?.lastname[0]}`;
        return (
          <CellContent>
            {data?.avatar ? <Avatar src={data.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
          </CellContent>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '20%' }}>Name</td>,
      render: (data: TUser) => (
        <CellContent>
          {data.id === me.id ? (
            <StyledLink to={routes.accountPage.make()}>
              {data.name} {data.lastname}
            </StyledLink>
          ) : (
            <StyledLink to={routes.adminUserDetails.make(data.id)}>
              {data.name} {data.lastname}
            </StyledLink>
          )}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contacts</td>,
      render: (data: TUser) => (
        <CellContent>
          <span style={{ display: 'block' }}>{data.email}</span>
          <span style={{ display: 'block' }}>{data.phone}</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Created</td>,
      render: (data: TUser) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Enabled</td>,
      render: (data: TUser) => {
        let onChange = () => changeEnabled(data);
        if (me.id === data.id) {
          onChange = async () => {};
        }
        return (
          <CellContent>
            <SwitchWrapper>
              <Switch checked={!data.disabled} onChange={onChange} disabled={me.id === data.id} />
            </SwitchWrapper>
          </CellContent>
        );
      },
    },
    {
      headComponent: () => (
        <td style={{ width: '3%', paddingTop: '6px' }} title="Downloaded & Signed in with Desktop App">
          <Desktop width="16px" />
        </td>
      ),
      render: (data: TUser) => (
        <CellContent>
          {data.signedInWithDesktop ? <DoubleDone width="24px" /> : data.downloadedDesktop ? <Done width="16px" /> : ''}
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Header
        title="Members"
        backRoute={routes.adminOrganizationsList.make()}
        leftSideContent={[
          <HeaderSwitchWrapper key="tabSwitcher">
            <Button
              onClick={() => {
                navigate(routes.adminOrganizationUsersList.make(id));
              }}
              className="button userButton active"
            >
              Members
            </Button>
            <Button
              onClick={() => {
                navigate(routes.adminOrganizationInvitationsList.make(id));
              }}
              className="button invitationButton"
            >
              Invitations
            </Button>
          </HeaderSwitchWrapper>,
        ]}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={users} />
      </Wrapper>
    </>
  );
};
