import styled from 'styled-components';

export const Wrapper = styled.main`
  padding: 20px 30px 50px;
  min-height: calc(100vh - 120px);

  .word {
    cursor: pointer;
    :hover {
      background: #858dbd;
    }
  }
`;
