import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0 0 20px;
  }

  .form-autocomplete {
    .select {
      box-shadow: 0 0 0 1px #858dbd;
    }
  }

  input {
    height: 30px;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
    cursor: pointer;
    margin: 12px 0;

    &.disabled {
      color: #d5def2;
      cursor: auto;
    }

    .text {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px 0 0;

    tbody td > * {
      width: 100%;
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;
