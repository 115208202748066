import { Button } from '@components/form-elements/buttons/Button';
import { Textarea } from '@components/form-elements/Textarea';
import { TProps } from './types/Props';

export const EditPrompt = ({
  systemPrompt,
  template,
  setSystemPrompt,
  setTemplate,
  setStage,
  updatedTemplate,
  updatedSystemPrompt,
}: TProps) => {
  return (
    <div className="first-stage">
      <div className="flex-block">
        <div className="left-wrapper h-full">
          <Textarea value={updatedSystemPrompt} onChange={(e) => setSystemPrompt(e.target.value)} label="" />
        </div>
      </div>
      <div className="flex-block">
        <div className="left-wrapper h-full">
          <Textarea value={updatedTemplate} onChange={(e) => setTemplate(e.target.value)} label="" />
        </div>
      </div>
      <Button
        disabled={template === updatedTemplate && systemPrompt === updatedSystemPrompt}
        onClick={() => setStage('preview')}
      >
        Preview
      </Button>
    </div>
  );
};
