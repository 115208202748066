import styled from 'styled-components';
import { formatPrice } from '@helpers/formatPrice';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';

const Wrapper = styled.div`
  width: 230px;
  height: 135px;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #d5def2;
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
  }

  .content {
    padding: 20px 20px 4px;
  }

  .info {
    padding: 0 20px;

    .rate {
      color: #40608f;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
    }
  }

  .description {
    padding: 8px 20px 0;
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  }
`;

type TProps = {
  className?: string;
  billingAccount?: TBillingAccount;
};

export const AudioHourRateCard = ({ className = '', billingAccount }: TProps) => {
  const rate = billingAccount?.tierRate ?? 0;
  const description = <div className="rate">{formatPrice(rate)}/Hr</div>;

  return (
    <Wrapper className={className}>
      <div className="content">
        <h2 className="title">Audio Hour Rate</h2>
      </div>

      <div className="info">{description}</div>
      <div className="description">Your cost per audio hour transcription</div>
    </Wrapper>
  );
};
