import { useState } from 'react';

import { useAPI } from '@hooks/useAPI';
import { SubscriptionsService } from 'app/API';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Button } from '@components/form-elements/buttons/Button';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const EditSubscriptionTranscriptUnlockTypeModal = ({
  baId,
  subscriptionId,
  initialValue,
  onCancel,
  onSuccess,
}: TProps) => {
  const { call } = useAPI();
  const [value, setValue] = useState(initialValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const values = [
    { label: 'ASOne Enhanced', value: 'ASONE_ENHANCED' },
    { label: 'Session Text', value: 'SESSION_TEXT' },
    { label: 'Choose', value: 'CHOOSE' },
  ];

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await call(
      SubscriptionsService.updateSubscriptionTranscriptUnlockTypes({
        baId,
        subscriptionId,
        requestBody: { transcriptUnlockType: value },
      }),
    );
    setIsSubmitting(false);
    onSuccess();
    onCancel();
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Update Transcript Unlock Type</h2>

      <SmallWhiteSelect
        name="value"
        label="Transcript Unlock Type"
        className="form-autocomplete"
        value={value}
        onChange={(_, value) => setValue(value)}
        options={values}
        required={false}
      />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Update</Button>
      </div>
    </Wrapper>
  );
};
