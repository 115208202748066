import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';

import ErrorIcon from './icons/error.svg';
import { StyledInput } from './styles/StyledInput';
import { TProps } from './types/TProps';

export const CurrencyInput = ({
  name = 'input',
  icon: Icon,
  label,
  onChange,
  onIconClick,
  value = '',
  placeholder = '',
  required = false,
  disabled = false,
  error = false,
  className = 'form-input',
  handleBlur,
  size,
  onKeyUp,
}: TProps) => {
  const defaultTitle = `${label}${required ? ' *' : ''}`;
  const placeholderTitle = placeholder ? placeholder : defaultTitle;
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    onBlur();
  }, [required]);

  const onFocus = () => {
    setTitle(placeholderTitle);
  };

  const onBlur = () => {
    setTitle(defaultTitle);
    handleBlur && handleBlur(null);
  };

  const renderIcon = () => {
    if (!Icon) {
      return null;
    }
    if (error) {
      return <ErrorIcon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
    }
    return <Icon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
  };

  return (
    <StyledInput className={className}>
      <CurrencyFormat
        onValueChange={(v) => {
          onChange(v.floatValue, v.value);
        }}
        thousandSeparator={true}
        placeholder={title}
        className={`input ${Icon || error ? 'with-icon' : ''} ${error ? 'error' : ''}`}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        size={size}
        onKeyUp={onKeyUp}
        prefix="$ "
      />
      {renderIcon()}
    </StyledInput>
  );
};
