import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { formatPrice } from '@helpers/formatPrice';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { usePageContext } from '@pages/Admin/BillingAccountsPage/providers/PageProvider';
import { PagePagination } from '@components/Pagination';

import { Wrapper } from './styles/Wrapper';
import { PlusBalanceIcon } from './styles/PlusBalanceIcon';
import { PencilIcon } from './styles/PencilIcon';
import { CellContent } from './styles/CellContent';
import { OrgCell } from './styles/OrgCell';
import { Button } from '@components/form-elements/buttons/Button';
import { useAuthContext } from '@providers/AuthProvider';
import { routes } from '@routes';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

export const Content = () => {
  const { me } = useAuthContext();
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  const {
    billingAccounts,
    isLoading,
    pageSize,
    setPageSize,
    search,
    setSearch,
    exportCSV,
    openBalanceModal,
    openRateModal,
    openRatesModal,
    recalculateBalances,
  } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: TBillingAccount, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Name</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          <StyledLink
            to={routes.adminBillingAccountDetails.make(data?.id)}
            className="font-medium text-queen-blue hover:text-primary"
          >
            {data.name}
          </StyledLink>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Organization</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {(data.organizations ?? []).map((org) => {
            if (!org) {
              return null;
            }
            return (
              <OrgCell key={org?.id}>
                <StyledLink to={routes.adminOrganizationDetails.make(org?.id)}>{org?.name}</StyledLink>
              </OrgCell>
            );
          })}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Balance</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              {formatCentsPrice(
                (data.MainBalance?.currentBalance ?? 0) + (data.FreeCreditBalance?.currentBalance ?? 0),
              )}
              {isVsBilling ? <PlusBalanceIcon onClick={() => openBalanceModal(data.id)} /> : null}
            </div>
            <div className="flex flex-row">
              <small className="mr-2">Main: {formatCentsPrice(data.MainBalance?.currentBalance ?? 0)}</small>
              <small>Free Credit: {formatCentsPrice(data.FreeCreditBalance?.currentBalance ?? 0)}</small>
            </div>
          </div>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Tier Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {data.fixedRate ? 'Fixed: ' : ''}
          {formatPrice(data.tierRate ?? 0)}/hr
          {isVsBilling ? <PencilIcon onClick={() => openRateModal(data.id)} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Post Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {data.billingConfiguration.postProcessingRateType === 'DISALLOWED' ? 'Disallowed' : null}
          {data.billingConfiguration.postProcessingRateType === 'TIER_RATE' ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.postProcessingRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.postProcessingRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data?.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Real-Time Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {data.billingConfiguration.realTimeRateType === 'DISALLOWED' ? 'Disallowed' : null}
          {data.billingConfiguration.realTimeRateType === 'TIER_RATE' ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.realTimeRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.realTimeRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data?.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Partial Unlock Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {data.billingConfiguration.partialUnlockRateType === 'DISALLOWED' ? 'Disallowed' : null}
          {data.billingConfiguration.partialUnlockRateType === 'TIER_RATE' ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.partialUnlockRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.partialUnlockRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Full Unlock Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {data.billingConfiguration.fullUnlockRateType === 'DISALLOWED' ? 'Disallowed' : null}
          {data.billingConfiguration.fullUnlockRateType === 'TIER_RATE' ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.fullUnlockRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.fullUnlockRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Recalculate Balance</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          <Button variant="outlined" onClick={() => recalculateBalances(data.id)}>
            Recalculate
          </Button>
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Header
        title="Billing Accounts"
        buttonLabel={billingAccounts.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={billingAccounts.data?.length ? () => exportCSV() : undefined}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={billingAccounts.data} />
        <PagePagination totalItemsCount={billingAccounts.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
      </Wrapper>
    </>
  );
};
