import { usePageContext } from '../../../../providers/PageProvider';
import { ShareLink } from './components/ShareLink';
import { GeneralSection } from './components/GeneralSection';
import { ScheduleSection } from './components/ScheduleSection';
import { MaterialsSection } from './components/MaterialsSection';
import { CommentsSection } from './components/CommentsSection';
import { AssigneeHistorySection } from './components/AssigneeHistorySection';

import { TopRightSection } from './styles/TopRightSection';
import { Divider } from './styles/Divider';
import { PipelineTimestamps } from './components/JobPipelineTimestamps';
import { StyledIconButton } from '@pages/Admin/JobsReportPage/components/Content/styles/StyledIconButton';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { Jobs } from '@components/icons/Jobs';
import { EnhancementSection } from './components/EnhancementSection';

export const MainContent = () => {
  const { job, isLoading, enhancements, manualReload } = usePageContext();
  const { openModal, closeModal } = useModalContext();

  const showResubmitJobModal = (id: string) => {
    openModal(ModalNamesEnum.AdminResubmitJobModal, {
      onSuccess: () => {
        closeModal();
        manualReload();
      },
      id,
      job,
    });
  };

  if (isLoading && !job) {
    return null;
  }
  if (!job) {
    return <h2>Job not found</h2>;
  }

  return (
    <>
      <TopRightSection>
        <ShareLink />
      </TopRightSection>

      <StyledIconButton title="Resubmit" onClick={() => showResubmitJobModal(job.id)}>
        <Jobs />
      </StyledIconButton>

      <GeneralSection />

      <Divider />

      {enhancements && (
        <>
          <EnhancementSection />
          <Divider />
        </>
      )}

      <ScheduleSection />

      <Divider />

      <MaterialsSection />

      <CommentsSection />

      <AssigneeHistorySection />

      <PipelineTimestamps data={job} />
    </>
  );
};
