import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #f8fafb;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 30px 90px;
  color: #40608f;

  overflow-x: hidden;
  overflow-y: auto;
  //max-height: 1030px;
  //max-width: 1250px;

  .close-icon {
    display: none;
    position: absolute;
    top: 22px;
    right: 30px;
    cursor: pointer;
    path {
      fill: #858dbd;
    }
    :hover {
      path {
        fill: #40608f;
      }
    }
  }

  a {
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }

  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
    color: #40608f;
    text-align: center;
    margin: 0;
    padding: 0 0 30px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #40608f;
    margin: 0;
    padding: 0 0 30px;

    b {
      font-weight: 600;
    }
  }

  .scrollContainer {
    overflow-x: auto;
    height: max-content;
    padding: 0 0 5px;

    table {
      min-width: 100%;
      width: max-content;
      tr {
        border-bottom: 1px solid #d5def2;
        &:last-child {
          border-bottom: none;
        }
        td:first-child,
        th:first-child {
          text-align: left;
          color: #40608f;
          padding-left: 0;
          font-size: 14px;
          line-height: 20px;
          max-width: 270px;
        }
        th:first-child {
          font-weight: 400;
          vertical-align: bottom;
        }
        td:first-child {
          font-weight: 500;
        }
        td:last-child,
        th:last-child {
          padding-right: 0;
        }
        td,
        th {
          padding: 10px 8px;
          text-align: center;

          svg {
            display: inline-block;
          }
        }
        td.in-person-channels {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
        th {
          .name {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            padding: 0 0 8px;
            text-align: left;
          }
          .price {
            display: flex;
            align-items: end;
            gap: 6px;
            font-weight: 600;
            font-size: 40px;
            line-height: 40px;
            padding: 0 0 8px;
            text-align: left;
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .fair-use {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #858dbd;
  }

  .divider {
    margin: 30px 0 30px;
  }

  .more-info {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 0 0 10px;

    b {
      font-weight: 600;
    }
  }
  .our-fair-usage-policy {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    b {
      font-weight: 600;
    }
  }

  .button-сontainer {
    display: flex;
    gap: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #f8fafb;
    padding: 30px;
    justify-content: space-between;
    width: 100%;

    .right {
      display: flex;
      gap: 10px;
    }
    button {
      width: max-content;
    }
  }

  @media (min-width: 1260px) {
    width: 1260px;
    max-height: 90vh;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: relative;

    .button-сontainer {
      position: relative;
      width: auto;
      padding: 40px 0;
      margin: 0 0 -90px;
    }
    .close-icon {
      display: block;
    }
  }
`;
