import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';

import { StyledUniversalNoPictureCard } from './styles/StyledUniversalNoPictureCard';

type TProps = {
  plan: TSubscriptionProduct;
  onChange: () => void;
};

export const Plan = ({ plan, onChange }: TProps) => {
  const { openModal } = useModalContext();
  const { me } = useAuthContext();
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  if (!plan) {
    return <></>;
  }

  const onEdit = () => {
    if (!isVsBilling) {
      return;
    }
    openModal(ModalNamesEnum.EditASOnePlan, {
      plan,
      onSuccess: () => onChange(),
    });
  };

  const isProduction = window.location.hostname.split('.')[0] === 'www';

  const content = (
    <>
      <p className="font-medium text-queen-blue">{plan.name}</p>
      <p className="text-primary text-xs">${plan.price} per license / month</p>
      {plan.DefaultBillingConfiguration ? (
        <>
          {plan.DefaultBillingConfiguration.realTimeRateType !== 'TIER_RATE' ? (
            <p className="text-primary text-xs">
              Realtime / ASR rate: ${plan.DefaultBillingConfiguration.realTimeRate} (
              {plan.DefaultBillingConfiguration.realTimeRateType})
            </p>
          ) : (
            <p className="text-primary text-xs">
              Realtime / ASR rate: {plan.DefaultBillingConfiguration.realTimeRateType}
            </p>
          )}
          {plan.DefaultBillingConfiguration.postProcessingRateType !== 'TIER_RATE' ? (
            <p className="text-primary text-xs">
              Post rate: ${plan.DefaultBillingConfiguration.postProcessingRate} (
              {plan.DefaultBillingConfiguration.postProcessingRateType})
            </p>
          ) : (
            <p className="text-primary text-xs">Post rate: {plan.DefaultBillingConfiguration.postProcessingRateType}</p>
          )}
          {plan.DefaultBillingConfiguration.partialUnlockRateType !== 'TIER_RATE' ? (
            <p className="text-primary text-xs">
              Reporter Unlock rate: ${plan.DefaultBillingConfiguration.partialUnlockRate} (
              {plan.DefaultBillingConfiguration.partialUnlockRateType})
            </p>
          ) : (
            <p className="text-primary text-xs">
              Full Unlock rate: {plan.DefaultBillingConfiguration.partialUnlockRateType}
            </p>
          )}
          {plan.DefaultBillingConfiguration.fullUnlockRateType !== 'TIER_RATE' ? (
            <p className="text-primary text-xs">
              Full Unlock rate: ${plan.DefaultBillingConfiguration.fullUnlockRate} (
              {plan.DefaultBillingConfiguration.fullUnlockRateType})
            </p>
          ) : (
            <p className="text-primary text-xs">
              Full Unlock rate: {plan.DefaultBillingConfiguration.fullUnlockRateType}
            </p>
          )}
        </>
      ) : null}
      <p className="text-primary text-xs">Free trial days: {plan.trialPeriodDays}</p>
      <p className="text-primary text-xs">Free seats: {plan.freeSeats}</p>
      <p className="text-primary text-xs">{plan.public ? 'Public' : 'Not public'}</p>
      {plan.allowedToChooseTranscriptUnlockType ? (
        <p className="text-primary text-xs">Allowed to choose Transcript Unlock Type</p>
      ) : null}
      <hr className="my-4" />
      <details>
        <summary className="text-primary text-xs">Stripe details</summary>
        <p className="text-primary text-xs">
          Plan ID:{' '}
          <a
            href={`https://dashboard.stripe.com/${isProduction ? '' : 'test/'}products/${plan.stripeProductId}`}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {plan.stripeProductId}
          </a>
        </p>
        <p className="text-primary text-xs">
          Price ID:{' '}
          <a
            href={`https://dashboard.stripe.com/${isProduction ? '' : 'test/'}prices/${plan.stripeItemPriceId}`}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {plan.stripeItemPriceId}
          </a>
        </p>
      </details>
    </>
  );

  const actions = isVsBilling ? [{ label: 'Edit', onClick: onEdit }] : [];

  return <StyledUniversalNoPictureCard className="plan-card" content={content} actions={actions} />;
};
