import styled from 'styled-components';

export const Wrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #40608f;

  & ul {
    padding-left: 18px;
    list-style: disc;
  }

  & li {
    padding: 5px 5px 5px 0;
  }
`;
