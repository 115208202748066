import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { BillingAccountService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';

import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Button } from '@components/form-elements/buttons/Button';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { Divider } from '@components/Divider';

import DoneIcon from './icons/done.svg';
import CrossIcon from './icons/cross.svg';
import { CloseIcon } from '@components/Modal/WhatsNew/icons/Close';
import { ASOneVideo } from '@components/Modal/ASOneVideo';

export const ChooseASOneSubscriptionModal = ({ onCancel, onSuccess, billingAccountId }: TProps) => {
  const { call } = useAPI();
  const { updateMe } = useAuthContext();
  const navigate = useNavigate();
  const [isDesktopTutorialOpen, setIsDesktopTutorialOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState<TSubscriptionProduct[]>([]);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setPlans([]);
      const [billingAccount, allPlans] = await Promise.all([
        call(BillingAccountService.findOne({ baId: billingAccountId })),
        call(BillingAccountService.getAsOnePlans()),
      ]);
      setBillingAccount(billingAccount);
      setPlans(allPlans.filter((plan: any) => plan.subscriptionProductCategory === 'AutoScriptOne'));
      setIsLoading(false);
    })();
  }, []);

  const goToContactSales: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate(routes.contactSales.make());
  };

  if (!billingAccount?.id) {
    return <></>;
  }

  const selectPlan = async (id: string) => {
    await updateMe();
    const plan = plans.find((item) => item.id === id);

    onSuccess({
      subscriptionProductId: id,
      planName: plan?.name ?? '',
      price: Number(plan?.price ?? 0),
      freeSeats: plan?.freeSeats || 0,
    });
  };

  const activeIds = (billingAccount?.subscriptions ?? [])
    .filter((sub) => {
      return sub.status === 'active' || sub.status === 'trialing';
    })
    .map((sub) => {
      return sub.productId;
    });
  const activePlans = plans.filter((plan) => {
    return activeIds.indexOf(plan.id) > -1;
  });
  const inactivePlans = plans
    .filter((plan) => {
      return activeIds.indexOf(plan.id) === -1;
    })
    .sort((a, b) => Number(a.price ?? 0) - Number(b.price ?? 0));

  if (isDesktopTutorialOpen) {
    return <ASOneVideo isOpen={true} onClose={() => setIsDesktopTutorialOpen(false)} />;
  }

  return (
    <Wrapper className="modal-overflow">
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 className="title">AutoScript One Real-Time Subscription Plans</h2>
      <CloseIcon className="close-icon" onClick={onCancel} />
      <h2 className="subtitle">
        <b>AutoScriptOne</b> is a cutting-edge Windows desktop App that revolutionizes digital court reporting through
        livestreaming Speech-To-Text to the Digital Reporter. This approach allows the reporter to provide instant
        readback or playback capabilities, while reducing overall delivery and transcript production costs. Discounts
        available for annual contracts.
        <br />
        <br />
        <b>14-Day Free Trial:</b> Enjoy a full-featured free trial. Credit card required. Cancel your subscription
        anytime. (See{' '}
        <a target="_blank" rel="noreferrer" href="/agreement.html">
          Terms of Service
        </a>
        )
      </h2>

      <div className="scrollContainer">
        <table className="table">
          <thead>
            <tr>
              <th>Features</th>
              {activePlans.map((plan) => {
                return (
                  <th key={plan.id}>
                    <div className="name">{plan.name}</div>
                    <div className="price">
                      ${Number(plan?.price ?? 0)} <span>per month</span>
                    </div>
                    <div className="button">
                      <Button disabled={true} variant="contained" onClick={() => {}}>
                        Current Plan
                      </Button>
                    </div>
                  </th>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <th key={plan.id}>
                    <div className="name">{plan.name}</div>
                    <div className="price">
                      ${Number(plan?.price ?? 0)} <span>per month</span>
                    </div>
                    <div className="button">
                      <Button variant="contained" onClick={() => selectPlan(plan.id)}>
                        Choose Plan
                      </Button>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Audio Recording Hours Per Month *</td>
              {activePlans.map((plan) => {
                return <td key={plan.id}>{plan.audioHoursPerMonth ?? 20}</td>;
              })}
              {inactivePlans.map((plan) => {
                return <td key={plan.id}>{plan.audioHoursPerMonth ?? 20}</td>;
              })}
            </tr>
            <tr>
              <td>RealTime Reporter AI Text Streaming</td>
              {activePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>High Quality Audio Capture</td>
              {activePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>AI Reporter Notes</td>
              {activePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Zoom Capture (Multi-channel integration)</td>
              {activePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Remote Multi-Channel Capture: (Teams, Webex, Google Meet, GoToMeeting)</td>
              {activePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    <DoneIcon width="30px" />
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>In-person Capture</td>
              {activePlans.map((plan) => {
                return (
                  <td className="in-person-channels" key={plan.id}>
                    Record up to {plan.inPersonCaptureChannels ?? 4} Channels
                  </td>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <td className="in-person-channels" key={plan.id}>
                    Record up to {plan.inPersonCaptureChannels ?? 4} Channels
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Enhanced AI Transcript (Post-session clean-up)</td>
              {activePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    {plan.allowedToChooseTranscriptUnlockType ? <DoneIcon width="30px" /> : <CrossIcon width="30px" />}
                  </td>
                );
              })}
              {inactivePlans.map((plan) => {
                return (
                  <td key={plan.id}>
                    {plan.allowedToChooseTranscriptUnlockType ? <DoneIcon width="30px" /> : <CrossIcon width="30px" />}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>

      <div className="fair-use">
        * Fair Use Overage Charges May Apply - See{' '}
        <a target="_blank" rel="noreferrer" href="/agreement.html">
          Terms of Service
        </a>
      </div>
      <Divider className="divider" />
      <div className="more-info">
        <b>Need More Information?</b> Contact our product specialist team for a demonstration or to discuss custom,
        multi-user pricing options.
      </div>
      <div className="our-fair-usage-policy">
        <b>Our Fair Usage Policy</b> is based on a rolling 3-month average of your audio hour usage. If your average
        monthly usage exceeds your plan’s limit by more than 10% in any given 3-month period, overage charges will
        apply. These charges will be automatically billed to your credit card at the time of your monthly subscription
        renewal. For organizations with multiple licenses, Fair Usage calculations are based on the combined total audio
        hours across all active plans. For more details, please review our Terms of Service.
      </div>

      <div className="button-сontainer">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <div className="right">
          <Button variant="outlined" onClick={() => setIsDesktopTutorialOpen(true)}>
            Watch Intro Video
          </Button>
          <Button variant="bold" onClick={goToContactSales}>
            Contact Our Sales Team
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};
