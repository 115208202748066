import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { routes } from '@routes';
import { bulkJobSubmission } from '@queries/repositories/bulkJobSubmission';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { PreviewFile } from '@components/FileUpload/components/PreviewFile';
import { UploadObjectType } from '@constants/enums/uploadObjectType';
import { formatDuration } from '@components/FileUpload/components/dropzone';
import { useAuthContext } from '@providers/AuthProvider';
import { Link as RouterLink } from 'react-router-dom';
import { getRepoAudioFiles } from '@queries/repositories/getRepoAudioFiles';
import { mapAttachmentsToIFileWithMeta, RepositoryAttachment } from '@pages/User/RepositoryPage/utils';
import { Block } from '@components/Block';
import { Switch } from '@components/form-elements/Switch';
import { TJob } from 'app/types/entities/TJob';
import { TRepository } from 'app/types/entities/TRepository';
import { TAudioFile } from 'app/types/entities/TAudioFile';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { MediaService, PayService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { Loader } from '@components/FileUpload/components/Loader';
import toast from 'react-hot-toast';

type TJobWithCreatedMedia = TJob & { createdMedia: any; analysis?: string };

type TProps = {
  repository: TRepository;
  audioFiles: TAudioFile[];
  organizationId: string;
  repositoryId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const StyledLink = styled(RouterLink)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #858dbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const StyledBlock = styled(Block)`
  padding-bottom: 0;
  text-align: left;

  .leftSide {
    padding-right: 25px;
    max-width: 200px;
  }

  .rightSide {
    display: flex;
    align-items: center;
  }

  .rightSide > * {
    margin-bottom: 0 !important;
  }
`;

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  // width: 330px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;

  .repositories-errorMessage {
    color: #ff2f2f;
    margin-top: -20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    margin: 0;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: 130px;
    }
  }
`;

enum BulkJobStep {
  GetAudioDuration = 'retrieving_audio_duration',
  RetrievingPrice = 'retrieving_price',
  RetrievedPrice = 'retrieved_price',
  CreatingDraftJobs = 'creating_draft_jobs',
  DraftJobsCreated = 'draft_jobs_created',
  JobsSubmittedSuccessfully = 'jobs_submitted_successfully',
  JobsSubmittedWithErrors = 'jobs_submitted_with_errors',
}

type BulkJobCreationModalButtonLabel = 'Create Jobs' | 'Submit Jobs' | 'Close';

export const BulkJobCreationModal = ({ repositoryId, organizationId, onCancel }: TProps) => {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [isAnalyzingAudio, setIsAnalyzingAudio] = useState(true);
  const [step, setStep] = useState(BulkJobStep.GetAudioDuration);
  const [totalAudioDuration, setTotalAudioDuration] = useState<number>();
  const [buttonLabel, setButtonLabel] = useState<BulkJobCreationModalButtonLabel>(
    'Create Jobs' as BulkJobCreationModalButtonLabel,
  );
  const [createdJobs, setCreatedJobs] = useState<TJobWithCreatedMedia[]>([]);
  const [audioFiles, setAudioFiles] = useState<TFileWithMeta[]>([]);
  const [dualChannel, setDualChannel] = useState(false);
  const { call } = useAPI();

  const { me, workspace, organization } = useAuthContext();

  const billingAccount = me?.organizations?.find((o) => o.id === organizationId)?.billingAccount;

  const totalPrice = totalAudioDuration ? (totalAudioDuration / 3600) * (Number(billingAccount?.tierRate) || 0) : 0;

  const pollAnalysisResult = (mediaId: string) => {
    let analysisChecksCount = 0;
    const interval = setInterval(async () => {
      const analysisResponse = await call(MediaService.getAnalysisStatus({ id: mediaId }));

      if (!analysisResponse) {
        clearInterval(interval);
      }

      if (analysisResponse.returnvalue) {
        setCreatedJobs((prev) =>
          prev.map((createdJob) => {
            if (createdJob.createdMedia.id === mediaId) {
              return {
                ...createdJob,
                createdMedia: { ...createdJob.createdMedia, duration: analysisResponse.returnvalue?.duration || 0 },
                analysis: 'complete',
              };
            }
            return createdJob;
          }),
        );
        clearInterval(interval);
        return;
      }

      analysisChecksCount++;

      if (analysisChecksCount >= 60 || analysisResponse.failedReason) {
        setCreatedJobs(((prev: TJobWithCreatedMedia[]) =>
          prev.map((createdJob: TJob & { createdMedia: any }) => {
            if (createdJob.createdMedia.id === mediaId) {
              return { ...createdJob, analysis: 'error' };
            }
            return createdJob;
          })) as any);
        clearInterval(interval);
        return;
      }
    }, 5000);
  };

  useEffect(() => {
    const isAnalysisInProcessing = createdJobs.some((job) => !job.analysis);
    setIsAnalyzingAudio(isAnalysisInProcessing);

    if (!isAnalysisInProcessing) {
      setTotalAudioDuration(createdJobs.reduce((acc, job: TJobWithCreatedMedia) => acc + job.createdMedia.duration, 0));
    }
  }, [createdJobs]);

  useEffect(() => {
    async function setup() {
      const audioFiles = await getRepoAudioFiles(repositoryId);
      setAudioFiles(mapAttachmentsToIFileWithMeta(audioFiles));

      const totalDuration = audioFiles
        .map((a: RepositoryAttachment) => a.audioDuration || 0)
        .reduce((acc: number, curr: number) => acc + curr, 0);

      setTotalAudioDuration(totalDuration);
      setStep(BulkJobStep.RetrievedPrice);
      setIsSubmitting(false);
    }

    if (step === BulkJobStep.GetAudioDuration) {
      setup();
    }
  }, [repositoryId, step]);

  const draftJobCreationHandler = async () => {
    if (!workspace?.id) {
      return;
    }

    setIsSubmitting(true);
    const data = await bulkJobSubmission(repositoryId, workspace?.id, dualChannel);
    console.log('draftJobCreationHandler', data);

    if (!data.error) {
      setCreatedJobs(data);
      data.forEach((job: TJobWithCreatedMedia) => {
        pollAnalysisResult(job.createdMedia.id);
      });

      setStep(BulkJobStep.DraftJobsCreated);
      setButtonLabel('Submit Jobs');
    } else {
      setError(data.error);
    }
    setIsSubmitting(false);
  };

  const jobSubmissionHandler = async () => {
    setIsSubmitting(true);

    await call(
      PayService.payForBulkJobs({
        requestBody: {
          ids: createdJobs
            .sort(
              (job: TJobWithCreatedMedia, nextJob: TJobWithCreatedMedia) =>
                Number(job.createdMedia.duration) - Number(nextJob.createdMedia.duration),
            )
            .map((job) => job.id),
        },
      }),
      {
        onError: (error: any) => {
          setStep(BulkJobStep.JobsSubmittedWithErrors);
          toast.error(error);
        },
        onSuccess: () => {
          setStep(BulkJobStep.JobsSubmittedSuccessfully);
        },
      },
    );

    setIsSubmitting(false);
    setButtonLabel('Close');
  };

  const onRemoveFile = (file: TFileWithMeta) => {
    const removeFilter = (f: TFileWithMeta) => f.meta.Id !== file.meta.Id;
    setAudioFiles([...audioFiles.filter(removeFilter)]);
  };

  return (
    <ModalWrapper>
      <h2>Bulk Job Creation</h2>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <div>
        {step === BulkJobStep.GetAudioDuration && <div>Retrieving audio duration...</div>}

        {step === BulkJobStep.RetrievedPrice && audioFiles.length === 0 && <div>No audio files found</div>}

        {step === BulkJobStep.RetrievedPrice &&
          audioFiles.map((a) => (
            <PreviewFile
              key={a.meta.Id}
              fileWithMeta={a}
              objectType={UploadObjectType.REPOSITORY}
              hideDownloadButton={true}
              hideRemoveButton={true}
              onRemoveFile={onRemoveFile}
            />
          ))}

        {step === BulkJobStep.DraftJobsCreated ? (
          <div>
            <p className="text-[#858DBD] font-[600] text-left text-sm pb-2">Draft jobs created</p>

            {isAnalyzingAudio ? (
              <p className="text-[#858DBD] font-[600] text-left text-sm pb-2">Analyzing audio...</p>
            ) : null}

            {createdJobs.map((job) => (
              <div className="flex my-2 items-center text-sm" key={job.id}>
                <StyledLink
                  to={routes.jobEdit.make(organization?.slug ?? '', workspace?.slug ?? '', job.id)}
                  className="mr-2"
                >
                  {job.name}
                </StyledLink>

                {!job.analysis ? (
                  <Loader size={14} />
                ) : job.analysis === 'complete' ? (
                  <div className="flex items-center">
                    <div className="mr-2">[{formatDuration(job.createdMedia.duration)},</div>
                    <div className="text-sm font-[600] text-right py-2">
                      ${((job.createdMedia.duration / 3600) * (Number(billingAccount?.tierRate) || 0)).toFixed(2)}
                    </div>
                    ]
                  </div>
                ) : (
                  <div className="text-red-500" style={{ fontSize: '0.775rem' }}>
                    Analysis error
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : null}

        {step === BulkJobStep.JobsSubmittedSuccessfully ? <div>Jobs submitted successfully</div> : null}

        {step === BulkJobStep.JobsSubmittedWithErrors ? (
          <div className="text-red-500">Jobs submitted with errors, please check job list</div>
        ) : null}
      </div>

      {error ? <div className="repositories-errorMessage">{error}</div> : null}

      {step === BulkJobStep.RetrievedPrice && audioFiles.length ? (
        <div>
          <StyledBlock title="Dual-Channel Call Processing">
            <Switch checked={dualChannel} onChange={() => setDualChannel(!dualChannel)} />
          </StyledBlock>
          <p className="text-xs text-left text-[#878787]">
            Enable this option if your phone call recording uses two channels <br />
            (e.g., one for each speaker).
          </p>
        </div>
      ) : null}

      {audioFiles.length > 0 ? (
        <table className="text-left text-xs border-collapse">
          <tbody>
            <tr className="border-b border-solid border-[#D5DEF2]">
              <td className="text-[#858DBD] font-[600] py-2">Total duration</td>
              <td className="text-sm text-right py-2">
                {totalAudioDuration ? formatDuration(totalAudioDuration) : <i>Loading...</i>}
              </td>
            </tr>
            <tr>
              <td className="text-[#858DBD] font-[600] py-2">Total price for bulk job</td>
              <td className="text-sm font-[600] text-right py-2">${totalPrice.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      ) : null}

      <div className="buttonContainer">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="ml-4"
          onClick={() => {
            if (step === BulkJobStep.RetrievedPrice) {
              return draftJobCreationHandler();
            }

            if (step === BulkJobStep.DraftJobsCreated) {
              return jobSubmissionHandler();
            }

            return onCancel();
          }}
          disabled={isSubmitting || (buttonLabel === 'Submit Jobs' && isAnalyzingAudio)}
        >
          {buttonLabel}
        </Button>
      </div>
    </ModalWrapper>
  );
};
