import { Fragment } from 'react';
import { TProps } from './types/TProps';
import { intervalToDuration } from 'date-fns';
import { WordDto } from 'app/API/models';
import { formatTime } from '@components/AudioPlayer/components/ProgressBar/helpers/formatTime';

const convertSecondsToTime = (seconds: number) => {
  const { hours, minutes, seconds: sec } = intervalToDuration({ start: 0, end: seconds });
  return `${String(hours || 0).padStart(2, '0')}:${String(minutes || 0).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
};

export const TranscriptLayout = ({ utterances, playbackTime, onWordClick }: TProps) => {
  const currentTimeMs = playbackTime * 1000;

  const speakerNames = new Set(utterances.map((utterance) => utterance.speaker));
  const speakers = Array.from(speakerNames);

  const longestSpeakerName = speakers.reduce((acc, speaker) => (speaker.length > acc.length ? speaker : acc), '');
  const speakerNameLength = longestSpeakerName.length === 1 ? `SPEAKER A`.length : longestSpeakerName.length;

  return (
    <div className="mt-24">
      <div className="mb-4">
        {speakers.map((speaker, idx) => (
          <div key={speaker}>
            <p className="text-sm">
              {speaker ? `Speaker ${String.fromCharCode(65 + idx)}: ${speaker}` : 'Unknown Speaker'}
            </p>
          </div>
        ))}
      </div>

      {utterances.map((utterance, utteranceIdx) => (
        <div className="flex" key={utteranceIdx}>
          <span
            className="flex-shrink-0 min-w-36 inline-block mr-4 font-mono"
            style={{ width: `${speakerNameLength + 12}ch` }}
          >
            <span>{convertSecondsToTime(utterance.start)}</span> |{' '}
            <span> {utterance.speaker.length === 1 ? `Speaker ${utterance.speaker}` : utterance.speaker}: </span>
          </span>
          <span className={`confidence-${utterance.confidence} inline-block`}>
            {utterance.words.map((word: WordDto, wordIdx: number) => {
              const nextWord = utterance.words[wordIdx + 1];
              const nextWordStart = nextWord ? nextWord.start : utterance.end!;
              const isActive = currentTimeMs >= word.start && currentTimeMs < nextWordStart;
              return (
                <Fragment key={wordIdx}>
                  <span
                    className={`word inline-block ${isActive ? 'bg-sky-300' : ''}`}
                    onClick={() => onWordClick(word.start)}
                    title={formatTime(word.start / 1000)}
                  >
                    {word.text}
                  </span>
                  &nbsp;
                </Fragment>
              );
            })}
          </span>
        </div>
      ))}
    </div>
  );
};
