export const getTranscriptUnlockTypeName = (v: string | undefined) => {
  if (!v) {
    return 'Undefined';
  }
  if (v === 'ASONE_ENHANCED') {
    return 'ASOne Enhanced';
  }
  if (v === 'SESSION_TEXT') {
    return 'Session Text';
  }
  if (v === 'CHOOSE') {
    return 'Choose';
  }
  return 'Unknown';
};
