import styled from 'styled-components';

export const InfoBlockContent = styled.div`
  color: #00122d;

  .pencil {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }
`;
