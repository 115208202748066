import { useState } from 'react';
import styled from 'styled-components';

import { SubscriptionsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { CheckBox } from '@components/form-elements/CheckBox';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  max-width: 480px;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0;
  }

  p.description {
    color: #00122d;
    margin: 30px 0;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    color: #858dbd;
    margin-bottom: 20px;
    a {
      text-decoration: underline;
      :hover {
        text-decoration: none;
      }
    }
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
  }
`;

const RangeSliderWrapper = styled.div`
  input[type='range'] {
    height: 4px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    box-shadow: 0 0 0 #000000;
    background: #e4ebf5;
    border-radius: 3px;
    border: 0 solid #010101;
  }

  input[type='range']::-webkit-slider-thumb {
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #e4ebf5;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    box-shadow: 0 0 0 #000000;
    background: #e4ebf5;
    border-radius: 3px;
    border: 0 solid #010101;
  }

  input[type='range']::-moz-range-thumb {
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: #e4ebf5;
    border: 0 solid #010101;
    border-radius: 6px;
    box-shadow: 0 0 0 #000000;
  }

  input[type='range']::-ms-fill-upper {
    background: #e4ebf5;
    border: 0 solid #010101;
    border-radius: 6px;
    box-shadow: 0 0 0 #000000;
  }

  input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
  }

  input[type='range']:focus::-ms-fill-lower {
    background: #e4ebf5;
  }

  input[type='range']:focus::-ms-fill-upper {
    background: #e4ebf5;
  }
`;

type TProps = {
  billingAccountId: string;
  subscriptionProductId: string;
  planName: string;
  price: number;
  freeSeats: number;
  onSuccess: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
};

export const ConfirmSubscription = ({
  billingAccountId,
  subscriptionProductId,
  planName,
  price,
  freeSeats,
  onCancel,
  onSuccess,
}: TProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [numberOfLicenses, setNumberOfLicenses] = useState(1);

  const innerSubmit = async () => {
    setIsSubmitting(true);
    const result = await call(
      SubscriptionsService.create({
        baId: billingAccountId,
        requestBody: { subscriptionProductId: subscriptionProductId, licenseCount: numberOfLicenses },
      }),
    );
    if (!result || !result.success) {
      setIsSubmitting(false);
      let message = 'Something went wrong. Please contact administrator.';
      if (result.error === 'No payment methods found') {
        message = 'Cannot create subscription. Please add a payment method first';
      }
      if (result.error === 'Subscription already exists') {
        message = 'You already have this subscription. Please choose another or modify an existing subscription';
      }
      onCancel();
      openModal(ModalNamesEnum.GenericErrorModal, {
        message,
      });
      return;
    }
    closeModal();
    onSuccess();
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 data-testid="purchasePlan">Confirm</h2>
      <p className="description">
        You are about to purchase the &quot;{planName}&quot; plan for ${price.toFixed(2)} per license per month +
        applicable sales tax
      </p>

      <RangeSliderWrapper>
        <label htmlFor="steps-range" className="block mb-2 font-[600] text-xs text-primary text-left">
          Number of licenses:
        </label>
        <input
          id="steps-range"
          type="range"
          min={1}
          max={250}
          value={numberOfLicenses}
          step={1}
          onChange={(e) => setNumberOfLicenses(Number(e.target.value))}
          className="w-full h-2 bg-[#E4EBF5] rounded-lg appearance-none cursor-pointer"
        />
        <span className="text-xs">
          {numberOfLicenses} license{numberOfLicenses > 1 && 's'}
        </span>
      </RangeSliderWrapper>

      <div className="text-left my-4 flex flex-col">
        {freeSeats ? (
          <div>
            <span className="font-[600] text-xs text-primary">Free licenses in subscription plan:</span>
            <span className="float-right">{freeSeats}</span>
          </div>
        ) : null}
        <div>
          <span className="font-[600] text-xs text-primary">Total subscription price per month:</span>
          <span className="float-right">
            ${numberOfLicenses <= freeSeats ? 0 : (Number(price) * (numberOfLicenses - freeSeats)).toFixed(2)}
          </span>
        </div>
      </div>

      <div className="checkbox-wrapper">
        <CheckBox checked={termsAccepted} onChange={(value) => setTermsAccepted(value)} />
        <label>
          i agree to the{' '}
          <a target="_blank" rel="noreferrer" href="/agreement.html">
            Terms of Service
          </a>
        </label>
      </div>

      <div className="buttonContainer">
        <Button type="button" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-testid="submitButton"
          type="submit"
          onClick={() => innerSubmit()}
          variant="contained"
          disabled={isSubmitting || !termsAccepted}
        >
          Purchase
        </Button>
      </div>
    </ModalWrapper>
  );
};
