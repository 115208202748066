import { Wrapper } from './styles/Wrapper';

export const DescriptionBlock = () => {
  return (
    <Wrapper className="descriptionBlock">
      <ul>
        <li>
          Access {"VoiceScript's"} desktop software, seamlessly retrieving real-time transcription from Zoom sessions
          via AutoScriptOne.
        </li>
        <li>
          Choose the license tier that suits your needs, enjoying the extensive features of AutoscriptOne, with a simple
          recurring monthly subscription.
        </li>
        <li>
          Experience the convenience of {"VoiceScript's"} downloadable desktop software for easy access to real-time
          transcription during your court reporting tasks.
        </li>
        <li>
          Unlock the perfect plan for your transcription needs: contact our sales team for custom pricing package.
        </li>
      </ul>
    </Wrapper>
  );
};
