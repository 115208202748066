import { TProps } from './types/TProps';

export const Plans = ({
  billingAccount,
  rate,
  showOnlyXLeft,
  onlyXLeftPosition,
  nextTierLeft,
  nextTierName,
  amount,
  setAmount,
}: TProps) => {
  const initialRate = parseFloat((billingAccount?.tierRate ?? 0).toString());
  if (!billingAccount) {
    return null;
  }
  if (billingAccount.fixedRate) {
    return <div className="rate">${rate}/audio hour</div>;
  }
  return (
    <div className="range-asr-wrapper">
      <div className="rates">
        <div className={`rate ${rate >= 15.0 ? 'active' : ''} ${initialRate >= 15.0 ? 'current' : ''}`}>
          <div className="rate-white">
            <div className="rate-inner">
              <div className="current">Current</div>
              <div className="rate-name">Starter</div>
              <div className="rate-requirements">&nbsp;</div>
              <div className="rate-price">$15.00/audio hour</div>
            </div>
          </div>
        </div>

        <div
          className={`rate ${rate === 13.5 ? 'active' : ''} ${
            initialRate === 13.5 || initialRate === 12.5 ? 'current' : ''
          }`}
        >
          <div className="rate-white">
            <div className="rate-inner freelancer">
              <div className="current">Current</div>
              <div className="rate-name">Freelancer</div>
              <div className="rate-requirements">Over $100.00</div>
              <div className="rate-price">$13.50/audio hour</div>
            </div>
          </div>
        </div>

        <div className={`rate ${rate === 12 ? 'active' : ''} ${initialRate === 12 ? 'current' : ''}`}>
          <div className="rate-white">
            <div className="rate-inner pro">
              <div className="current">Current</div>
              <div className="rate-name">Pro</div>
              <div className="rate-requirements">Over $500.00</div>
              <div className="rate-price">$12.00/audio hour</div>
            </div>
          </div>
        </div>

        <div className={`rate ${rate === 11 ? 'active' : ''} ${initialRate === 11 ? 'current' : ''}`}>
          <div className="rate-white">
            <div className="rate-inner agency">
              <div className="current">Current</div>
              <div className="rate-name">Agency</div>
              <div className="rate-requirements">Over $1,000.00</div>
              <div className="rate-price">$11.00/audio hour</div>
            </div>
          </div>
        </div>

        <div
          className={`rate ${rate === 10 ? 'active' : ''} ${initialRate === 10 || initialRate === 9 || initialRate === 8 ? 'current' : ''}`}
        >
          <div className="rate-white">
            <div className="rate-inner business">
              <div className="current">Current</div>
              <div className="rate-name">Business</div>
              <div className="rate-requirements">Over $2,000.00</div>
              <div className="rate-price">$10.00/audio hour</div>
            </div>
          </div>
        </div>
      </div>

      <div className="range-wrapper">
        <div
          className="only-x-left"
          style={{ display: `${showOnlyXLeft ? 'block' : 'none'}`, left: `${onlyXLeftPosition}%` }}
        >
          Only ${nextTierLeft}
          <br />
          to reach the {nextTierName} tier!
        </div>
        <input
          id="steps-range"
          type="range"
          min={10}
          max={2500}
          value={amount}
          step={1}
          onChange={(e) => setAmount(Number(e.target.value))}
          className="range w-full h-2 bg-[#E4EBF5] rounded-lg appearance-none cursor-pointer"
        />
      </div>
    </div>
  );
};
