import React, { useEffect, useState } from 'react';

import { useModalContext } from '@providers/ModalProvider';

import { BillingAccountService, PaymentService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { formatPrice } from '@helpers/formatPrice';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { TPaymentMethod } from 'app/types/entities/TPaymentMethod';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';

import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';
import { Plans } from '@components/Modal/Billing/TopUpCreditBalance/components/Plans';
import { PaymentMethod } from '@components/Modal/Billing/TopUpCreditBalance/components/PaymentMethod';

export const TopUpCreditBalanceModal = ({ billingAccountId, onCancel, onSuccess, initialAmount = 10 }: TProps) => {
  const { closeModal, openModal } = useModalContext();
  const { call } = useAPI();
  let initAmount = initialAmount;
  if (initAmount < 10) {
    initAmount = 10;
  }
  const [amount, setAmount] = useState(initAmount);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();
  const [rate, setRate] = useState(parseFloat((billingAccount?.tierRate ?? 0).toString()));
  const [promoRate, setPromoRate] = useState(0);
  const [resultAmount, setResultAmount] = useState((billingAccount?.currentBalance ?? 0) / 100 + 10);
  const [paymentMethods, setPaymentMethods] = useState<{
    default: TPaymentMethod;
    all: TPaymentMethod[];
  }>();
  const [isLoading, setIsLoading] = useState(false);

  const onlyXLeftPosition = ((amount - 10) / 2570) * 100;
  function calculateNextTier() {
    if (rate < 11) {
      return { nextTierLeft: 0, nextTierName: '', showOnlyXLeft: false };
    }
    if (rate > 10 && resultAmount > 1750) {
      return { nextTierLeft: Math.ceil(2000 - resultAmount), nextTierName: 'Business', showOnlyXLeft: true };
    }
    if (rate > 11 && resultAmount > 750) {
      return { nextTierLeft: Math.ceil(1000 - resultAmount), nextTierName: 'Agency', showOnlyXLeft: true };
    }
    if (rate > 12 && resultAmount > 400) {
      return { nextTierLeft: Math.ceil(500 - resultAmount), nextTierName: 'Pro', showOnlyXLeft: true };
    }
    if (rate > 13.5 && resultAmount > 50) {
      return { nextTierLeft: Math.ceil(100 - resultAmount), nextTierName: 'Freelancer', showOnlyXLeft: true };
    }
    return { nextTierLeft: 0, nextTierName: '', showOnlyXLeft: false };
  }
  const { nextTierLeft, nextTierName, showOnlyXLeft } = calculateNextTier();

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const billingAccount = await call(BillingAccountService.findOne({ baId: billingAccountId }));
      setBillingAccount(billingAccount);
      const paymentMethods = await call(PaymentService.billingAccountPaymentMethodList({ baId: billingAccountId }));

      setPaymentMethods({
        default: paymentMethods.find((item) => item.default) || paymentMethods[0],
        all: paymentMethods,
      });
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!billingAccount) {
      return;
    }
    const defaultRate = parseFloat((billingAccount?.tierRate ?? 0).toString());
    const currentBalance = (billingAccount.currentBalance ?? 0) / 100;
    const newAmount = amount + currentBalance;

    // If fixed rate is set
    if (billingAccount?.fixedRate) {
      setRate(defaultRate);
      setPromoRate(0);
      setResultAmount(newAmount);
      return;
    }

    // Otherwise, calculate the rate based on the amount
    let newRate = defaultRate;
    if (newAmount >= 2000) {
      newRate = 10;
    } else if (newAmount >= 1000) {
      newRate = 11;
    } else if (newAmount >= 500) {
      newRate = 12;
    } else if (newAmount >= 100) {
      newRate = 13.5;
    }
    setResultAmount(newAmount);
    setRate(newRate);
  }, [amount, billingAccount]);

  const onSubmit = async () => {
    setIsLoading(true);
    const result = await call(
      PaymentService.createPaymentIntent({
        baId: billingAccountId,
        requestBody: {
          amount: amount * 100,
          balanceTopup: true,
        },
      }),
    );
    if (['succeeded', 'processing'].includes(result.status)) {
      openModal(ModalNamesEnum.TransactionStatus, {
        chargeId: result.latest_charge,
        pics: result.client_secret,
        onSuccess,
      });
      setIsLoading(false);
      return;
    }
    // 3D Secure
    // todo: maybe make it open in a modal with an iframe???
    if (result.status === 'requires_action' && result.next_action?.type === 'redirect_to_url') {
      setIsLoading(false);
      window.location = result.next_action.redirect_to_url.url;
      return;
    }
    if ((result.status ?? '').includes('requires_')) {
      // requires_
      openModal(ModalNamesEnum.Status, {
        title: 'Payment Status',
        message: (
          <>
            Something went wrong. Please try again later <br /> (Status code: {result.status})
          </>
        ),
        status: 'error',
        buttonMessage: 'Ok',
        onSuccess,
      });
      setIsLoading(false);
      return;
    }
    // canceled?
    setIsLoading(false);
    closeModal();
    onSuccess();
  };

  const isSubmitDisabled = isLoading || !paymentMethods?.default || amount < 10;

  const renderAmountLine = () => {
    return (
      <div className="amount-line">
        <div className="input-wrapper">
          <div className="label">Amount (USD) *</div>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.value.length) {
                setAmount(0);
                return;
              }
              setAmount(parseInt(e.target.value, 10));
            }}
            label="Amount"
            value={amount}
          />
        </div>
        <div className="input-wrapper">
          <div className="label">Balance after top-up</div>
          <div className="balance">{formatPrice(resultAmount)}</div>
        </div>
        <div className="input-wrapper">
          <div className="label">Estimated audio hours</div>
          <div className="balance">
            {promoRate && rate ? (
              <>
                <span>{Math.floor(resultAmount / rate)}</span>
              </>
            ) : rate ? (
              Math.floor(resultAmount / rate)
            ) : (
              'Loading...'
            )}
          </div>
        </div>
        <div className="input-wrapper new-rate">
          <div className="label">Your new rate</div>
          <div className="balance">
            {promoRate && rate ? (
              <>
                <span>{`$${rate.toFixed(2).toString()}`}</span>
              </>
            ) : rate ? (
              `$${rate.toFixed(2).toString()}`
            ) : (
              'Loading...'
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Prepaid Credit Balance</h2>

      <div className="holiday-wrapper">
        <div className="description">
          <p className="centered font-bold">Great News!</p>
          <p>Our Special Pricing Promotion has been extended through March 2025!</p>
          <p>
            With reduced Top-up Balance Levels, it&apos;s easier than ever to lock in a lower rate and enjoy extended
            savings. Don&apos;t wait. Top up and Save today. You deserve it!
          </p>
        </div>
      </div>

      <div className="input-wrapper">
        <div className="label">Payment method</div>
        <PaymentMethod defaultPaymentMethod={paymentMethods?.default} isLoading={isLoading} />
      </div>
      <div className="input-wrapper">
        <div className="label">Rate</div>
        <Plans
          amount={amount}
          billingAccount={billingAccount}
          nextTierLeft={nextTierLeft}
          nextTierName={nextTierName}
          onlyXLeftPosition={onlyXLeftPosition}
          rate={rate}
          setAmount={setAmount}
          showOnlyXLeft={showOnlyXLeft}
        />
      </div>

      {renderAmountLine()}

      <div className="description">* Note: minimum top up amount is $10</div>
      <div className="description">** Balance update may take up to 1 minute after the payment</div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitDisabled} onClick={onSubmit}>
          Pay now
        </Button>
      </div>
    </Wrapper>
  );
};
