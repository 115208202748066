import { useState } from 'react';
import { DatePicker } from '@components/form-elements/DatePicker';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { CloseIcon } from '@components/icons/Close';
import { CheckBox } from '@components/form-elements/CheckBox';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';
import { TUserFilter } from '@components/todo/ReportFilter/types/TUserFilter';

const Close = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
    fill: #858dbd;
  }
`;

const Wrapper = styled.div`
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: default;

  .label {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    padding: 0 0 15px;
    color: #858dbd;
  }

  .datepicker-wrapper {
    padding: 0 0 12px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0 0 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #858dbd;
  }
`;

const FilterTitle = styled.h3`
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 0 15px;
  color: #858dbd;
  border-bottom: 1px solid #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`;

const SuggestionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
`;

const UsersCreatedWrapper = styled.div`
  margin-top: 20px;
`;

const BottomButton = styled(Button)`
  width: 25%;
  margin-right: 10px;
`;

const firstDayOfCurrentWeek = () => {
  const dayOfWeek = new Date().getDay();
  const firstDayOfWeek = new Date();
  const diff = dayOfWeek >= 0 ? dayOfWeek : 6 - dayOfWeek;
  firstDayOfWeek.setDate(new Date().getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);
  return firstDayOfWeek;
};

const firstDayOfCurrentMonth = () => {
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDate() + 1);
  firstDayOfMonth.setHours(0, 0, 0, 0);
  return firstDayOfMonth;
};

const startOfCurrentDay = () => {
  const currentDay = new Date();
  currentDay.setHours(0, 0, 0, 0);
  return currentDay;
};

const getSelectedDateFilterValue = (jobsFilter: TJobsFilter) => {
  let to = jobsFilter.to || 0;
  let from = jobsFilter.from || 0;
  const today = startOfCurrentDay().getTime();
  const thisWeekStart = firstDayOfCurrentWeek().getTime();
  const thisMonthStart = firstDayOfCurrentMonth().getTime();
  if (from) {
    from = parseInt(new Date(from).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (to) {
    to = parseInt(new Date(to).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (from === today && to === today) {
    return 'today';
  }
  if (from === thisWeekStart && to === today) {
    return 'week';
  }
  if (from === thisMonthStart && to === today) {
    return 'month';
  }
  return '';
};

type TProps = {
  userFilter?: TUserFilter;
  userFilterOnChange?: (arg: TUserFilter) => void;
  jobsFilter: TJobsFilter;
  jobsFilterOnChange: (arg: TJobsFilter) => void;
  statusFilter: string[];
  statusFilterOnChange: (arg: string[]) => void;
  handleSubmit: () => void;
  handleClose: () => void;
  asOneFilter?: boolean;
  setAsOneFilter?: (v: boolean) => void;
};

export const Filter = ({
  userFilter,
  userFilterOnChange,
  jobsFilter,
  jobsFilterOnChange,
  statusFilter,
  statusFilterOnChange,
  handleSubmit,
  handleClose,
  asOneFilter,
  setAsOneFilter,
}: TProps) => {
  const [tempDateFilterSelected, setTempDateFilterSelected] = useState(getSelectedDateFilterValue(jobsFilter));
  const [tempJobsFilter, setTempJobsFilter] = useState(jobsFilter);
  const [tempUserFilter, setTempUserFilter] = useState(userFilter);
  const [tempStatusFilter, setTempStatusFilter] = useState(statusFilter);
  const [tempAsOneFilter, setTempAsOneFilter] = useState(asOneFilter ?? false);

  const tempSubmit = () => {
    if (userFilterOnChange) {
      userFilterOnChange(tempUserFilter ?? {});
    }
    if (setAsOneFilter) {
      setAsOneFilter(tempAsOneFilter);
    }
    if (jobsFilterOnChange) {
      jobsFilterOnChange(tempJobsFilter);
    }
    if (statusFilterOnChange) {
      statusFilterOnChange(tempStatusFilter);
    }
    handleSubmit();
    handleClose();
  };
  const tempClose = () => {
    if (userFilterOnChange) {
      userFilterOnChange(userFilter ?? {});
    }
    if (setAsOneFilter) {
      setAsOneFilter(asOneFilter ?? false);
    }
    if (jobsFilterOnChange) {
      jobsFilterOnChange(jobsFilter);
    }
    if (statusFilterOnChange) {
      statusFilterOnChange(statusFilter);
    }
    handleClose();
  };

  const statuses = [
    { value: 'JOB_STATUS_DRAFT', label: 'Draft' },
    { value: 'JOB_STATUS_ERROR', label: 'Error' },
    { value: 'JOB_STATUS_SCHEDULED', label: 'Scheduled' },
    { value: 'JOB_STATUS_PROCESSING', label: 'Processing' },
    { value: 'JOB_STATUS_READY_FOR_TRANSCRIBING', label: 'Ready for Editing' },
    { value: 'JOB_STATUS_TRANSCRIBING', label: 'Editing' },
    { value: 'JOB_STATUS_READY_FOR_PROOFREADING', label: 'Ready for Proofreading' },
    { value: 'JOB_STATUS_PROOFING', label: 'Proofreading' },
    { value: 'JOB_STATUS_READY_FOR_FINAL', label: 'Ready for final' },
    { value: 'JOB_STATUS_FINALIZING', label: 'Finalizing' },
    { value: 'JOB_STATUS_COMPLETE', label: 'Complete' },
    { value: 'AS_ONE_LOCKED', label: 'Locked' },
  ];

  return (
    <Wrapper>
      <FilterTitle>Filters</FilterTitle>
      <Close onClick={tempClose}>
        <CloseIcon />
      </Close>

      <div className="label">Status</div>
      {statuses.map((status) => (
        <div className="checkbox-wrapper" key={status.value}>
          <CheckBox
            onChange={() => {
              let newFilter = [...tempStatusFilter];
              if (tempStatusFilter.indexOf(status.value) > -1) {
                newFilter = newFilter.filter((v: string) => v !== status.value);
              } else {
                newFilter.push(status.value);
              }
              setTempStatusFilter(newFilter);
            }}
            checked={tempStatusFilter.indexOf(status.value) > -1}
          />
          <label>{status.label}</label>
        </div>
      ))}
      {setAsOneFilter ? (
        <div className="checkbox-wrapper">
          <CheckBox
            onChange={() => {
              setTempAsOneFilter(!tempAsOneFilter);
            }}
            checked={tempAsOneFilter}
          />
          <label>ASOne</label>
        </div>
      ) : null}

      <div className="label">Jobs submitted</div>
      <div className="datepicker-wrapper">
        <DatePicker
          label="From"
          minDate={new Date(0)}
          maxDate={tempJobsFilter.to || new Date()}
          value={tempJobsFilter.from}
          onChange={(date: Date | null) =>
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = date ?? undefined;
              return newVal;
            })
          }
        />
      </div>

      <div className="datepicker-wrapper">
        <DatePicker
          label="To"
          minDate={tempJobsFilter.from}
          maxDate={new Date()}
          value={tempJobsFilter.to}
          onChange={(date: Date | null) =>
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              let newDate = null;
              if (date) {
                const dateObj = new Date(date);
                dateObj.setHours(23);
                dateObj.setMinutes(59);
                newDate = dateObj;
              }
              newVal.to = newDate ?? undefined;
              return newVal;
            })
          }
        />
      </div>

      <SuggestionButtonsWrapper>
        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'today'}
          onClick={() => {
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = startOfCurrentDay();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('today');
          }}
        >
          Today
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'week'}
          onClick={() => {
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = firstDayOfCurrentWeek();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('week');
          }}
        >
          This week
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'month'}
          onClick={() => {
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = firstDayOfCurrentMonth();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('month');
          }}
        >
          This month
        </Button>
      </SuggestionButtonsWrapper>

      {userFilter && userFilterOnChange ? (
        <UsersCreatedWrapper>
          <div className="label">Users created</div>
          <div className="datepicker-wrapper">
            <DatePicker
              minDate={new Date(0)}
              maxDate={tempUserFilter?.userTo ?? new Date()}
              value={tempUserFilter?.userFrom}
              onChange={(date: Date | null) => setTempUserFilter({ ...tempUserFilter, userFrom: date })}
              label="From"
            />
          </div>
          <div className="datepicker-wrapper">
            <DatePicker
              minDate={tempUserFilter?.userFrom ?? undefined}
              maxDate={new Date()}
              value={tempUserFilter?.userTo}
              onChange={(date: Date | null) =>
                setTempUserFilter((v) => {
                  const newVal: TUserFilter = Object.assign({}, v);
                  let newDate = null;
                  if (date) {
                    const dateObj = new Date(date);
                    dateObj.setHours(23);
                    dateObj.setMinutes(59);
                    newDate = dateObj;
                  }
                  newVal.userTo = newDate ?? undefined;
                  return newVal;
                })
              }
              label="To"
            />
          </div>
        </UsersCreatedWrapper>
      ) : null}

      <ButtonsWrapper>
        <BottomButton
          variant="outlined"
          onClick={() => {
            if (userFilterOnChange) {
              setTempUserFilter((v) => {
                const newVal = Object.assign({}, v);
                newVal.userFrom = undefined;
                newVal.userTo = undefined;
                return newVal;
              });
            }
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = undefined;
              newVal.to = undefined;
              return newVal;
            });
            setTempDateFilterSelected('');
            setTempStatusFilter([]);
            if (userFilterOnChange) {
              userFilterOnChange({ userFrom: undefined, userTo: undefined });
            }
            if (jobsFilterOnChange) {
              jobsFilterOnChange({ from: undefined, to: undefined });
            }
            if (statusFilterOnChange) {
              statusFilterOnChange([]);
            }
            handleSubmit();
            handleClose();
          }}
        >
          Reset
        </BottomButton>
        <BottomButton onClick={tempSubmit}>Apply</BottomButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};
