import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';

import { AdminService } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { formatPrice } from '@helpers/formatPrice';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useSearchParams } from 'react-router-dom';

type TState = {
  billingAccounts: TPaginatedResponse<TBillingAccount>;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  sortingField: string;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  reloadPage: () => void;
  exportCSV: () => void;
  openBalanceModal: (billingAccountId: string) => void;
  openRateModal: (billingAccountId: string) => void;
  openRatesModal: (billingAccountId: string) => void;
  recalculateBalances: (billingAccountId: string) => void;
};

const PageContext = createContext<TState>({
  billingAccounts: {
    data: [],
    count: 0,
  },
  isLoading: false,
  setIsLoading: () => null,
  sortingField: 'createdAt',
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  reloadPage: () => null,
  exportCSV: () => null,
  openBalanceModal: () => null,
  openRateModal: () => null,
  openRatesModal: () => null,
  recalculateBalances: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { openModal } = useModalContext();
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;
  const [search, setSearch] = useState('');
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [billingAccounts, setBillingAccounts] = useState<TPaginatedResponse<TBillingAccount>>({
    data: [],
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reloadPage();
  }, [page, pageSize, reloadPageTrigger, search]);

  const reloadPage = async () => {
    setIsLoading(true);
    const billingAccounts = await call(
      AdminService.getBillingAccountsList({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
      }),
    );
    setBillingAccounts(billingAccounts);
    setIsLoading(false);
  };

  const exportCSV = async () => {
    const items = await call(
      AdminService.getBillingAccountsList({
        pageNo: '1',
        pageSize: billingAccounts.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search,
      }),
    );
    const headers = ['ID', 'Name', 'Organization', 'Balance', 'Rate', 'Created'];
    const array = items.data.map((data: TBillingAccount) => {
      let rate = data.fixedRate ? 'Fixed: ' : '';
      rate += `${formatPrice(data.tierRate ?? 0)}/hr}`;
      return {
        id: data.id,
        name: data.name,
        organization: (data.organizations ?? []).map((org) => org.name).join(', '),
        balance: formatCentsPrice(data.currentBalance ?? 0),
        rate,
        created: getAmericanTime(data.createdAt),
      };
    });
    return downloadCSV(array, headers);
  };

  const openBalanceModal = (billingAccountId: string) => {
    openModal(ModalNamesEnum.AdminAddBalanceToBillingAccount, {
      billingAccountId,
      onSuccess: () => {
        setReloadPageTrigger((prevState) => !prevState);
      },
    });
  };

  const openRateModal = (billingAccountId: string) => {
    openModal(ModalNamesEnum.AdminBillingAccountRate, {
      billingAccountId,
      onSuccess: () => {
        setReloadPageTrigger((prevState) => !prevState);
      },
    });
  };

  const openRatesModal = (billingAccountId: string) => {
    openModal(ModalNamesEnum.AdminBillingAccountRatesEditor, {
      billingAccountId,
      onSuccess: () => {
        setReloadPageTrigger((prevState) => !prevState);
      },
    });
  };

  const triggerRecalculateBalances = async (billingAccountId: string) => {
    await call(AdminService.recalculateBalances({ baId: billingAccountId }));
    setReloadPageTrigger((prevState) => !prevState);
  };

  return (
    <PageContext.Provider
      value={{
        billingAccounts,
        isLoading,
        setIsLoading,
        sortingField,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        reloadPage,
        exportCSV,
        openBalanceModal,
        openRateModal,
        openRatesModal,
        recalculateBalances: triggerRecalculateBalances,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
