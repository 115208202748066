import { routes } from '@routes';

import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { usePageContext } from '../../../../../../providers/PageProvider';

import { JobSection } from '../../styles/JobSection';
import { InfoBlockContent } from '../../styles/InfoBlockContent';
import { JobSectionLeft } from '../../styles/JobSectionLeft';
import { JobSectionRight } from '../../styles/JobSectionRight';
import { InfoBlockWrapper } from '../../styles/InfoBlockWrapper';
import { InfoBlock } from '../../styles/InfoBlock';
import { InfoBlockTitle } from '../../styles/InfoBlockTitle';
import { StyledLink } from '../../styles/StyledLink';

import { AssigneeBlockContent } from './styles/AssigneeBlockContent';
import { JobRightRow } from './styles/JobRightRow';
import { EJobPipelineStatuses } from '@constants/enums/jobPipelineStatuses';
import { getTranscriptUnlockTypeName } from '@helpers/getTranscriptUnlockTypeName';

export const GeneralSection = () => {
  const { job } = usePageContext();

  if (!job) {
    return null;
  }

  return (
    <JobSection>
      <JobSectionLeft>General</JobSectionLeft>
      <JobSectionRight>
        <JobRightRow>{job.name}</JobRightRow>
        <InfoBlockWrapper>
          <InfoBlock>
            <InfoBlockTitle>Job ID</InfoBlockTitle>
            <InfoBlockContent>{job.id}</InfoBlockContent>
          </InfoBlock>

          <InfoBlock>
            <InfoBlockTitle>Status</InfoBlockTitle>
            <InfoBlockContent>{job.asOneLocked ? 'Locked' : JOB_STATUSES_ENUM[job.status]}</InfoBlockContent>
          </InfoBlock>

          <InfoBlock>
            <InfoBlockTitle>Pipeline Status</InfoBlockTitle>
            <InfoBlockContent>{EJobPipelineStatuses[job.pipelineStatus ?? 'not_started']}</InfoBlockContent>
          </InfoBlock>

          {job.bucket ? (
            <InfoBlock>
              <InfoBlockTitle>S3 Bucket</InfoBlockTitle>
              <InfoBlockContent>
                <a
                  href={`https://s3.console.aws.amazon.com/s3/buckets/${job.bucket}?region=us-east-1&prefix=jobs/${job.id}/`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Link
                </a>
              </InfoBlockContent>
            </InfoBlock>
          ) : null}

          {job.versions?.length ? (
            <InfoBlock>
              <InfoBlockTitle>Last version link</InfoBlockTitle>
              <InfoBlockContent>
                <a
                  href={`https://s3.console.aws.amazon.com/s3/buckets/${job.bucket}?region=us-east-1&prefix=jobs/${job.id}/versions/${job.versions[0].id}/`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Link
                </a>
              </InfoBlockContent>
            </InfoBlock>
          ) : null}

          <InfoBlock>
            <InfoBlockTitle>Transcript Unlock Type</InfoBlockTitle>
            <InfoBlockContent className="align-middle flex gap-2">
              {getTranscriptUnlockTypeName(job.transcriptUnlockType)}
            </InfoBlockContent>
          </InfoBlock>

          <InfoBlock>
            <InfoBlockTitle>Date created</InfoBlockTitle>
            <InfoBlockContent>{getAmericanTime(job.createdAt)}</InfoBlockContent>
          </InfoBlock>

          {job.deadline ? (
            <InfoBlock>
              <InfoBlockTitle>Deadline</InfoBlockTitle>
              <InfoBlockContent>{getAmericanTime(job.deadline, false)}</InfoBlockContent>
            </InfoBlock>
          ) : null}

          <InfoBlock>
            <InfoBlockTitle>Assignee</InfoBlockTitle>
            <AssigneeBlockContent>
              {job?.assignee ? (
                <div className="assignee">
                  <StyledLink to={routes.adminUserDetails.make(job?.assignee?.id)}>
                    <p className="assignName">
                      {job?.assignee?.name} {job?.assignee?.lastname}
                    </p>
                    <p className="assignEmail">{job?.assignee?.email || ''}</p>
                  </StyledLink>
                </div>
              ) : null}
            </AssigneeBlockContent>
          </InfoBlock>

          <InfoBlock>
            <InfoBlockTitle>Transcription confidence</InfoBlockTitle>
            <InfoBlockContent>
              {job?.transcriptConfidence ? (
                <StyledLink to={routes.adminJobUtterances.make(job?.id)}>
                  {`~${(Number(job.transcriptConfidence ?? 0) * 100).toFixed(2)} %`}
                </StyledLink>
              ) : (
                '---'
              )}
            </InfoBlockContent>
          </InfoBlock>

          <InfoBlock>
            <InfoBlockTitle>Average job audio score</InfoBlockTitle>
            <InfoBlockContent>{job?.averageJobAudioScore ? job.averageJobAudioScore : '---'}</InfoBlockContent>
          </InfoBlock>
        </InfoBlockWrapper>
      </JobSectionRight>
    </JobSection>
  );
};
