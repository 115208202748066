import styled from 'styled-components';
import { UniversalNoPictureCard } from '@components/UniversalNoPictureCard';

export const StyledUniversalNoPictureCard = styled(UniversalNoPictureCard)`
  height: auto;
  min-height: 72px;

  .card {
    position: relative;
    align-items: start;
    .actions {
      padding: 0;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .card-text {
      width: 270px;
      .font-medium {
        padding: 0 0 10px;
      }
      p {
        white-space: wrap;
        padding: 0 0 3px;
      }
    }
  }
`;
