import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 400px;

  h2 {
    color: #858dbd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 0;
    padding: 0 0 30px;
    text-align: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .name {
      color: #00122d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      padding: 0 0 20px;
    }

    input {
      height: 30px;
    }
  }

  .flex-row {
    margin: 0 0 20px;

    .input-wrapper,
    .switch-wrapper {
      max-width: 166px;
    }

    .form-select {
      min-width: 166px;
    }

    .input-wrapper {
      .input,
      .textarea {
        margin: 0;
      }
    }
  }

  .card,
  .rate {
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 20px;

    &.red {
      color: red;
    }
  }

  .description {
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;
