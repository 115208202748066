import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { AdminService, BillingAccountService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import { DescriptionBlock } from '../../ASOne/ChooseASOneSubscriptionModal/components/DescriptionBlock';

import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';
import { Subscription } from '@components/Modal/ASOne/ChooseASOneSubscriptionModal/components/Subscription';

export const AdminChooseASOneSubscriptionModal = ({ onCancel, onSuccess, billingAccountId }: TProps) => {
  const { call } = useAPI();
  const { updateMe } = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allPlans, setAllPlans] = useState<TSubscriptionProduct[]>([]);
  const [plans, setPlans] = useState<TSubscriptionProduct[]>([]);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setPlans([]);
      const billingAccount = await call(AdminService.getBillingAccount({ baId: billingAccountId }));
      setBillingAccount(billingAccount);
      const allPlans = await call(BillingAccountService.getAllAsOnePlans());
      setAllPlans(allPlans);
      setPlans(allPlans.filter((plan: any) => plan.subscriptionProductCategory === 'AutoScriptOne'));
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setPlans(allPlans.filter((plan) => plan.subscriptionProductCategory === 'AutoScriptOne'));
  }, []);

  const goToContactSales: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate(routes.contactSales.make());
  };

  if (!billingAccount?.id) {
    return <></>;
  }

  const selectPlan = async (id: string) => {
    await updateMe();
    const plan = plans.find((item) => item.id === id);

    onSuccess({
      subscriptionProductId: id,
      planName: plan?.name ?? '',
      price: Number(plan?.price ?? 0),
      freeSeats: plan?.freeSeats || 0,
    });
  };

  const activeIds = (billingAccount?.subscriptions ?? [])
    .filter((sub) => {
      return sub.status === 'active' || sub.status === 'trialing';
    })
    .map((sub) => {
      return sub.productId;
    });
  const activePlans = plans.filter((plan) => {
    return activeIds.indexOf(plan.id) > -1;
  });
  const inactivePlans = plans.filter((plan) => {
    return activeIds.indexOf(plan.id) === -1;
  });

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 className="cardTitle">Pricing Plans</h2>

      <div className="scrollContainer">
        <div className="cardContainer">
          {activePlans.map((plan) => {
            return <Subscription key={plan.id} plan={plan} isActive={true} onChoose={() => selectPlan(plan.id)} />;
          })}
          {inactivePlans.map((plan) => {
            return <Subscription key={plan.id} plan={plan} isActive={false} onChoose={() => selectPlan(plan.id)} />;
          })}
        </div>
      </div>

      <DescriptionBlock />

      <div className="buttonModalContainer">
        <Button variant="bold" onClick={goToContactSales}>
          Contact Our Sales Team
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Wrapper>
  );
};
