import { routes } from '@routes';

import { usePageContext } from '../../../../../../providers/PageProvider';

import { JobSection } from '../../styles/JobSection';
import { InfoBlockContent } from '../../styles/InfoBlockContent';
import { JobSectionLeft } from '../../styles/JobSectionLeft';
import { JobSectionRight } from '../../styles/JobSectionRight';
import { InfoBlockWrapper } from '../../styles/InfoBlockWrapper';
import { InfoBlock } from '../../styles/InfoBlock';
import { InfoBlockTitle } from '../../styles/InfoBlockTitle';
import { StyledLink } from '../../styles/StyledLink';

export const EnhancementSection = () => {
  const { job, enhancements, metadata } = usePageContext();

  if (!job || !enhancements || !metadata) {
    return null;
  }

  const enhancementCountByRule =
    enhancements?.enhanced_transcript?.reduce<Record<string, number>>((acc, curr) => {
      curr.corrections.forEach((correction) => {
        if (acc[correction.rule]) {
          acc[correction.rule]++;
        } else {
          acc[correction.rule] = 1;
        }
      });
      return acc;
    }, {}) ?? {};

  return (
    <JobSection>
      <JobSectionLeft>Enhancements</JobSectionLeft>
      <JobSectionRight>
        <InfoBlockWrapper>
          <InfoBlock>
            <InfoBlockTitle>Proceeding Type</InfoBlockTitle>
            <InfoBlockContent className="align-middle flex gap-2">{metadata.proceeding_type}</InfoBlockContent>
          </InfoBlock>
          <InfoBlock>
            <InfoBlockTitle>Parties</InfoBlockTitle>
            <InfoBlockContent className="align-middle flex gap-2">
              <ul>
                {metadata.parties.map((party, idx) => {
                  return (
                    <li key={idx} className="text-xs mb-1">
                      {party}
                    </li>
                  );
                })}
              </ul>
            </InfoBlockContent>
          </InfoBlock>
          <InfoBlock>
            <InfoBlockTitle>Inferred Speaker Count</InfoBlockTitle>
            <InfoBlockContent>
              {metadata?.speakers.length ? (
                <StyledLink to={routes.adminJobTranscriptPlayer.make(job?.id)}>
                  Speaker count: {metadata?.speakers.length}
                </StyledLink>
              ) : (
                '---'
              )}
            </InfoBlockContent>
          </InfoBlock>
          {(enhancements.enhanced_transcript.length || 0) > 0 ? (
            <InfoBlock>
              <InfoBlockTitle>AI corrections</InfoBlockTitle>
              <InfoBlockContent>
                <div className="text-xs mb-4">
                  <ul className="mt-2">
                    {Object.keys(enhancementCountByRule ?? {}).map((rule, idx) => (
                      <li key={idx}>
                        {rule}: {enhancementCountByRule[rule] ?? 0}
                      </li>
                    ))}
                  </ul>
                </div>
              </InfoBlockContent>
            </InfoBlock>
          ) : null}
        </InfoBlockWrapper>

        <InfoBlockWrapper>
          {metadata &&
            metadata?.speakers?.map((speaker, idx) => (
              <InfoBlock key={idx}>
                <InfoBlockTitle>{speaker.post_asr_label}</InfoBlockTitle>
                <InfoBlockContent>
                  <div className="text-xs mb-4">
                    <p>
                      <small>First name:</small> {speaker.first_name}
                    </p>
                    <p>
                      <small>Last name:</small> {speaker.last_name}
                    </p>
                    <p>
                      <small>Role:</small> {speaker.role}
                    </p>
                    <p>
                      <small>Prefix:</small> {speaker.honorific_prefix}
                    </p>
                    <p>
                      <small>ASOne label:</small> {speaker.real_time_asr_label}
                    </p>
                  </div>
                </InfoBlockContent>
              </InfoBlock>
            ))}
        </InfoBlockWrapper>

        <div className="w-3/4 mt-4">
          <InfoBlockTitle>Summary</InfoBlockTitle>
          <InfoBlockContent>
            <div className="text-sm mb-4">{metadata.summary}</div>
          </InfoBlockContent>
        </div>
      </JobSectionRight>
    </JobSection>
  );
};
