import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { usePageContext } from '../../providers/PageProvider';
import { TranscriptLayout } from './components/TranscriptLayout';

import { Wrapper } from './styles/Wrapper';
import { AudioPlayer, AudioPlayerHandle } from '@components/AudioPlayer';
import { useRef, useState } from 'react';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';

export const Content = () => {
  const { isLoading, utterances, transcriptFile, setTranscriptFile, audioUrl } = usePageContext();
  const [playbackTime, setPlaybackTime] = useState(0);
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);
  const seekTo = (timestamp: number) => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.seekTo(timestamp);
    }
  };

  return (
    <Wrapper>
      {isLoading && <LinearProgressLoader active={isLoading} />}

      <div className="fixed bg-white p-4 shadow-md w-full z-10 mb-10">
        <div className="flex items-center gap-4">
          <div className="w-1/4">
            <SmallWhiteSelect
              name="transcriptFile"
              label="Transcript File"
              value={transcriptFile}
              disabled={isLoading}
              onChange={(_label, value) => setTranscriptFile(value)}
              options={[
                {
                  label: 'Assembly Post ASR',
                  value: 'asr.json',
                },
                {
                  label: 'ASOne Realtime Transcript',
                  value: 'transcript.json',
                },
                {
                  label: 'Enhanced Transcript',
                  value: 'llm-transcript.json',
                },
              ]}
              required={false}
            />
          </div>

          <div className="w-1/2">
            {audioUrl && (
              <AudioPlayer
                src={audioUrl}
                ref={audioPlayerRef}
                onPlay={(timestamp) => {
                  setPlaybackTime(timestamp);
                }}
              />
            )}
          </div>
        </div>
      </div>

      {!isLoading && (
        <TranscriptLayout
          utterances={utterances}
          playbackTime={playbackTime}
          onWordClick={(timestamp) => seekTo(timestamp / 1000)}
        />
      )}
    </Wrapper>
  );
};
