import { getHourPrice } from '@helpers/getHourPrice';
import { formatRate } from '@helpers/formatRate';

import { Wrapper } from './styles/Wrapper';
import { StyledButton } from './styles/StyledButton';

type TProps = {
  plan: any;
  isActive: boolean;
  onChoose: () => void;
};
export const Subscription = ({ plan, isActive, onChoose }: TProps) => {
  if (!plan.DefaultBillingConfiguration) {
    return null;
  }
  const billingConfiguration = plan.DefaultBillingConfiguration;
  return (
    <Wrapper key={plan.id} className="card" active={isActive}>
      <div className="content">
        <div className="plan-name">{plan.name.replace('AutoScriptOne ', '')}</div>
        <div className="price">{getHourPrice(plan.capacityInSeconds, Number(plan.price))}</div>
        <h3 className={billingConfiguration.postProcessingRateType === 'DISALLOWED' ? 'line-through' : ''}>
          {formatRate(billingConfiguration.postProcessingRate, billingConfiguration.postProcessingRateType, 'Post ASR')}
        </h3>
        <h3 className={billingConfiguration.realTimeRateType === 'DISALLOWED' ? 'line-through' : ''}>
          {formatRate(billingConfiguration.realTimeRate, billingConfiguration.realTimeRateType, 'Real-time ASR')}
        </h3>
        <h3 className={billingConfiguration.partialUnlockRateType === 'DISALLOWED' ? 'line-through' : ''}>
          {formatRate(
            billingConfiguration.partialUnlockRate,
            billingConfiguration.partialUnlockRateType,
            'Reporter Unlock',
          )}
        </h3>
        <h3 className={billingConfiguration.fullUnlockRateType === 'DISALLOWED' ? 'line-through' : ''}>
          {formatRate(billingConfiguration.fullUnlockRate, billingConfiguration.fullUnlockRateType, 'Full Unlock')}
        </h3>

        {isActive ? <div className="chosenContainer">Your Current Plan</div> : null}
        {!isActive ? (
          <div className="buttonContainer">
            <StyledButton disabled={false} size={'medium'} variant={'text'} onClick={onChoose}>
              Choose Plan
            </StyledButton>
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
};
